import api from 'src/api';
import { ApiStatus } from 'src/constants';

export const openSignInLink = async (id: string, newTab?: boolean) => {
  try {
    const { data } = await api.get(`/purchases/${id}/documents/purchaser`);
    window.open(data, newTab ? '_blank' : '_self', 'noopener,noreferrer');
  } catch (err) {
    console.error(err);
  }
};

export const getOrderData = async (orderId: string) => {
  if (!orderId) {
    return null;
  }

  try {
    const result = await api.get(`/purchases/${orderId}`);
    if (result.status === ApiStatus.SUCCESS) {
      return result.data;
    }

    return null;
  } catch (err) {
    console.error('err', err);
    return null;
  }
};

export const getOrderPaymentData = async (id: string) => {
  try {
    const result = await api.get(`/payments/${id}`);
    if (result.status === ApiStatus.SUCCESS) {
      return result.data;
    }

    return null;
  } catch (err) {
    console.error('err', err);
    return null;
  }
};
