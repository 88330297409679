import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Input } from 'src/components/ui-components';

import { Regex } from 'src/constants';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { InstallmentPaymentAmountProps } from './installment-payment-amount.props';

import './installment-payment-amount.scss';

const InstallmentPaymentAmount = observer(
  forwardRef<HTMLInputElement, InstallmentPaymentAmountProps>(
    ({ className, formStore, isFirstPayment }, ref) => {
      const {
        amount,
        remainingBalance: balance,
        customValue,
        isCustomValueValid,
        isLoading,
        handleCustomValueChange,
      } = formStore;
      const paymentType = isFirstPayment ? 'Down' : 'Regular';

      const handleCustomValueBlur = () => {
        if (customValue && Regex.MONEY_USD.test(customValue)) {
          const rawValue = Number(customValue.replace(/[$,]/g, ''));
          const rawValueInCents = rawValue * 100;

          if (rawValueInCents > balance) {
            handleCustomValueChange(`$${(balance / 100).toFixed(2)}`);
          } else {
            handleCustomValueChange(`$${rawValue.toFixed(2)}`);
          }
        }
      };

      const handleCustomValueFocus = () => {
        if (!customValue) {
          handleCustomValueChange('$');
        }
      };

      const renderDownPaymentText = () => (
        <p className="installment-payment-amount__down-text">
          Down payment, is an initial up-front partial payment for the purchase
          of expensive items/services.
        </p>
      );

      const renderPaymentHints = () => (
        <div className="installment-payment-amount__hints">
          <div className="installment-payment-amount__hint">
            <span className="installment-payment-amount__hint-text">
              Payment Amount:
            </span>
            <button
              className="installment-payment-amount__hint-btn"
              disabled={isLoading}
              onClick={() =>
                handleCustomValueChange(formatAsUSDOptionalCents(amount))
              }
            >
              {formatAsUSDOptionalCents(amount)}
            </button>
          </div>
          <div className="installment-payment-amount__hint">
            <span className="installment-payment-amount__hint-text">
              Remaining Balance:
            </span>
            <button
              className="installment-payment-amount__hint-btn"
              disabled={isLoading}
              onClick={() =>
                handleCustomValueChange(formatAsUSDOptionalCents(balance))
              }
            >
              {formatAsUSDOptionalCents(balance)}
            </button>
          </div>
        </div>
      );

      return (
        <div className={classNames('installment-payment-amount', className)}>
          <h2 className="installment-payment-amount__title">
            {paymentType} Payment
          </h2>
          {isFirstPayment ? renderDownPaymentText() : renderPaymentHints()}
          <Input
            className="installment-payment-amount__custom-value"
            ref={ref}
            id="customValueInput"
            isDisabled={isLoading}
            isValid={isCustomValueValid}
            onBlur={handleCustomValueBlur}
            onFocus={handleCustomValueFocus}
            onChange={handleCustomValueChange}
            placeholder="Enter custom value"
            value={customValue}
          />
        </div>
      );
    },
  ),
);

export default InstallmentPaymentAmount;
