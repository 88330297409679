import { observer } from 'mobx-react-lite';

import Order from '../order';

import ordersStore from 'src/stores/orders-store';

import { OrderType } from 'src/constants';
import { getOrderAdditionalLabel } from 'src/utils';

import './orders.scss';

const Orders = observer(() => (
  <ul className="orders">
    {ordersStore.signinOrders.map((item) => (
      <Order
        key={item.id}
        id={item.id}
        agreementNumber={item.agreementNumber}
        dueDate={ordersStore.getOrderDueDate(item.id)}
        lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
        price={item.payment?.total}
        balance={ordersStore.getOrderRemainingBalance(item.id)}
        type={OrderType.SIGNING}
        createdAt={item.createdAt}
        payment={ordersStore.getOrderNextPayment(item.id)}
        additionalInfo={getOrderAdditionalLabel(item)}
      />
    ))}
    {ordersStore.activeOrders.map((item) => (
      <Order
        key={item.id}
        id={item.id}
        agreementNumber={item.agreementNumber}
        dueDate={ordersStore.getOrderDueDate(item.id)}
        lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
        price={item.payment?.total}
        balance={ordersStore.getOrderRemainingBalance(item.id)}
        type={OrderType.ACTIVE}
        payment={ordersStore.getOrderNextPayment(item.id)}
        list={ordersStore.getOrderPaymentList(item.id)}
        createdAt={item.createdAt}
        additionalInfo={getOrderAdditionalLabel(item)}
      />
    ))}
    {ordersStore.completedOrders.map((item) => (
      <Order
        key={item.id}
        id={item.id}
        agreementNumber={item.agreementNumber}
        dueDate={ordersStore.getOrderDueDate(item.id)}
        lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
        price={item.payment?.total}
        balance={ordersStore.getOrderRemainingBalance(item.id)}
        type={OrderType.COMPLETED}
        createdAt={item.createdAt}
        additionalInfo={getOrderAdditionalLabel(item)}
      />
    ))}
  </ul>
));

export default Orders;
