import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { ButtonProps } from './button.props';

import './button.scss';

const Button = ({
  className,
  download,
  href = '',
  icon,
  isDisabled,
  isTabletSizeGrowth,
  label,
  onClick,
  size,
  tag = 'button',
  target,
  theme: buttonTheme,
  type = 'button',
  ...props
}: ButtonProps) => {
  const buttonClasses = classNames(
    'button',
    buttonTheme && `button_${buttonTheme}`,
    size && `button_size_${size}`,
    icon && 'button_with-icon',
    isDisabled && 'button_disabled',
    isTabletSizeGrowth && 'button_growth',
    className,
  );

  const renderInnerElements = () => (
    <>
      {icon && <span className="button__icon">{icon}</span>}
      <span className="button__label">{label}</span>
    </>
  );

  switch (tag) {
    case 'button':
      return (
        <button
          aria-label={label}
          className={buttonClasses}
          onClick={onClick}
          type={type}
          disabled={isDisabled}
          {...props}
        >
          {renderInnerElements()}
        </button>
      );
    case 'link':
      return (
        <Link
          aria-label={label}
          className={buttonClasses}
          to={href as string}
          onClick={onClick}
          target={target || '_self'}
          {...props}
        >
          {renderInnerElements()}
        </Link>
      );
    case 'a':
      return (
        <a
          aria-label={label}
          className={buttonClasses}
          download={download}
          href={href as string}
          onClick={onClick}
          rel="noopener noreferrer"
          target={target || '_blank'}
          {...props}
        >
          {renderInnerElements()}
        </a>
      );
    default:
      return null;
  }
};

export default Button;
