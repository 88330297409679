import type { FormEvent } from 'react';
import classNames from 'classnames';

import type { CheckboxProps } from './checkbox.props';

import './checkbox.scss';

function Checkbox({
  children,
  className,
  label,
  id,
  isChecked,
  isMultiple,
  isDisabled,
  onChange,
}: CheckboxProps) {
  const handleChange = (e: FormEvent<HTMLInputElement>) =>
    onChange(e.currentTarget.checked);

  return (
    <div
      className={classNames(
        'checkbox',
        isMultiple && 'checkbox_multiple',
        isDisabled && 'checkbox_disabled',
        className,
      )}
    >
      <input
        className="checkbox__input"
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <label htmlFor={id} className="checkbox__label">
        <span className="checkbox__icon-wrapper">
          <span className="checkbox__icon" />
        </span>
        {label}
        {children}
      </label>
    </div>
  );
}

export default Checkbox;
