import React from 'react';

import { LabeledValue } from 'src/components/ui-components';

import { GRAVE_PROPERTIES, MAUSOLEUM_PROPERTIES } from 'src/constants';
import { getLot } from 'src/utils';
import type { PropertyListProps } from './property-list.props';

import './property-list.scss';

const PropertyList = ({ lots }: PropertyListProps) => (
  <ul className="property-list">
    <li className="property-list__item property-list__item_header">
      <span className="property-list__header">Property Name</span>
      <span className="property-list__header">Price</span>
    </li>

    {lots.map((lot) => {
      const mausoleumeName = lot.mausoleum;
      const properties = mausoleumeName
        ? MAUSOLEUM_PROPERTIES
        : GRAVE_PROPERTIES;
      const lotProperties = properties.filter(({ field }) => !!lot[field]);

      return (
        <li key={lot.number} className="property-list__item">
          <span className="property-list__item-lot">
            <span className="property-list__item-name">{getLot(lot)}</span>
            <span className="property-list__item-properties">
              {mausoleumeName && (
                <LabeledValue
                  className="property-list__mausoleum-name"
                  key="Mausoleum"
                  label="Mausoleum"
                  value={mausoleumeName}
                  valueWeight="medium"
                />
              )}
              {lotProperties.map(({ label, field }) => (
                <LabeledValue
                  key={field}
                  label={label}
                  value={`${lot[field]}`}
                  valueWeight="medium"
                />
              ))}
              <LabeledValue
                label="Rights"
                value={lot.right}
                valueWeight="medium"
              />
            </span>
          </span>
          <span className="property-list__item-price">{lot.price || '-'}</span>
        </li>
      );
    })}
  </ul>
);

export default PropertyList;
