import React from 'react';

import type { RecordProps } from './record.props';

import './record.scss';

const Record = ({ name, value }: RecordProps) => {
  const recordValue =
    Array.isArray(value) && value.length
      ? value.map((v) => (
          <span key={v} className="record__value-item">
            {v}
          </span>
        ))
      : value;
  return (
    <div className="record">
      <span className="record__key">{name}:</span>
      <span className="record__value">{recordValue}</span>
    </div>
  );
};

export default Record;
