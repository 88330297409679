import { Elements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import useStripe from 'src/pages/payment-page/hooks/use-stripe';
import { Loader } from 'src/components/common-components';
import BankTransferForm from '../bank-transfer-form';
import CashForm from '../cash-form';
import CreditCardForm from '../credit-card-form';
import PaypalButton from '../paypal-button';

import {
  IS_BANK_TRANSFER_METHOD_AVAILABLE,
  IS_PAYPAL_METHOD_AVAILABLE,
  IS_STRIPE_METHOD_AVAILABLE,
  PaymentMethod,
  PaymentMethodApi,
} from 'src/constants';
import type { PaymentMethodFormProps } from './payment-method-form.props';

import './payment-method-form.scss';

const PaymentMethodForm = observer(
  ({
    bankTransferStore,
    cashCheckStore,
    creditCardStore,
    paypalStore,
    isMorePaymentsAhead,
    isPaymentAmount,
    loadingPayment,
    paymentOption,
    stripeAccount,
  }: PaymentMethodFormProps) => {
    const { stripePromise } = useStripe(stripeAccount);
    const extraDataClassNames = {
      [PaymentMethod[PaymentMethodApi.BANK_TRANSFER]]:
        'payment-method-form_bank-transfer',
    };

    return (
      <div
        className={classNames(
          'payment-method-form',
          extraDataClassNames[paymentOption.value],
        )}
      >
        {IS_STRIPE_METHOD_AVAILABLE &&
          (stripePromise ? (
            <Elements stripe={stripePromise} options={{ locale: 'en' }}>
              {creditCardStore && (
                <CreditCardForm
                  creditCardStore={creditCardStore}
                  isCardCanBeSaved={isMorePaymentsAhead}
                  isVisible={
                    paymentOption.apiValue ===
                    PaymentMethodApi.DEBIT_CREDIT_CARD
                  }
                  loadingPayment={loadingPayment}
                />
              )}
            </Elements>
          ) : (
            <Loader />
          ))}
        {cashCheckStore && (
          <CashForm
            cashCheckStore={cashCheckStore}
            isVisible={paymentOption.apiValue === PaymentMethodApi.CASH}
            loadingPayment={loadingPayment}
          />
        )}
        {bankTransferStore && IS_BANK_TRANSFER_METHOD_AVAILABLE && (
          <BankTransferForm
            bankTransferStore={bankTransferStore}
            isVisible={
              paymentOption.apiValue === PaymentMethodApi.BANK_TRANSFER
            }
          />
        )}
        {IS_PAYPAL_METHOD_AVAILABLE && paypalStore && (
          <PaypalButton
            isDisabled={!isPaymentAmount}
            isVisible={paymentOption.apiValue === PaymentMethodApi.PAYPAL}
            paypalStore={paypalStore}
          />
        )}
      </div>
    );
  },
);

export default PaymentMethodForm;
