import { makeAutoObservable } from 'mobx';

import BankTransferStore from 'src/stores/payment-form/methods/bank-transfer-store';
import CashCheckStore from 'src/stores/payment-form/methods/cash-check-store';
import CreditCardStore from 'src/stores/payment-form/methods/credit-card-store';
import PaypalStore from 'src/stores/payment-form/methods/paypal-store';
import processingStore from 'src/stores/processing-store';

import api from 'src/api';
import {
  Error,
  IS_BANK_TRANSFER_METHOD_AVAILABLE,
  IS_PAYPAL_METHOD_AVAILABLE,
  IS_STRIPE_METHOD_AVAILABLE,
  PAYMENT_METHOD_OPTIONS,
  PaymentMethod,
  PaymentMethodApi,
  PaymentTypeApi,
  Regex,
} from 'src/constants';
import { formatAsUSDOptionalCents, formatUSDMoneyToCents } from 'src/utils';
import type {
  IOption,
  IOrder,
  IPaymentMethodOption,
  ISinglePayment,
} from 'src/interfaces';

const DEFAULT_PAYMENT_OPTION = PAYMENT_METHOD_OPTIONS[0];

class PaymentFormStore {
  customValue: string = '';
  formError: string = '';
  isLoading: boolean = false;
  order: IOrder;
  payment: ISinglePayment;
  paymentMethodOptions: IPaymentMethodOption[] = PAYMENT_METHOD_OPTIONS;
  selectedPaymentOption: IPaymentMethodOption = DEFAULT_PAYMENT_OPTION;

  // payment methods stores
  bankTransferStore: BankTransferStore | null = null;
  cashCheckStore: CashCheckStore | null = null;
  creditCardStore: CreditCardStore | null = null;
  paypalStore: PaypalStore | null = null;

  constructor(order: IOrder, payment: ISinglePayment) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.order = order;
    this.payment = payment;
    this.customValue = formatAsUSDOptionalCents(payment.amount);
    processingStore.connectSocket(payment.id);

    this.cashCheckStore = new CashCheckStore();
    if (IS_STRIPE_METHOD_AVAILABLE) {
      this.creditCardStore = new CreditCardStore(
        this.setFormError,
        order?.purchasers[0].rawAddress,
      );
    }
    if (IS_BANK_TRANSFER_METHOD_AVAILABLE) {
      this.bankTransferStore = new BankTransferStore(
        this.setFormError,
        this.resetLoading,
        payment.id,
      );
    }
    if (IS_PAYPAL_METHOD_AVAILABLE) {
      this.paypalStore = new PaypalStore(
        this.setFormError,
        payment.id,
        payment.currentBalance,
      );
    }
  }

  setCustomValue(value: string) {
    this.customValue = value;
  }

  setFormError(error: string) {
    this.formError = error;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setSelectedPaymentOption(paymentOption: IPaymentMethodOption) {
    this.selectedPaymentOption = paymentOption;
  }

  resetLoading() {
    this.setIsLoading(false);
  }

  handlePaymentOptionChange(optionLabel: string) {
    const option = PAYMENT_METHOD_OPTIONS.find(
      (item: IOption) => item.value === optionLabel,
    )!;
    this.setSelectedPaymentOption(option);
    if (this.formError) {
      this.setFormError('');
    }
  }

  handleCustomValueChange(value: string) {
    if (Regex.MONEY_USD.test(value)) {
      this.setCustomValue(value);
    }
  }

  updateAmount(updatedAmountPaymentHandler: (val: number) => void) {
    if (!this.remainingBalance) {
      return;
    }

    const formattedValue = formatUSDMoneyToCents(this.customValue) || 0;
    const updatedAmount =
      formattedValue >= this.remainingBalance
        ? this.remainingBalance
        : formattedValue;

    updatedAmountPaymentHandler(updatedAmount);
  }

  async handlePaymentButtonClick(evt: any) {
    evt.preventDefault();
    this.setIsLoading(true);

    if (this.selectedPaymentOption.value === PaymentMethod.CASH_CHECK) {
      processingStore.disconnectSocket();
      this.updateAmount(this.sendPatchAmountUpdate);
    } else {
      this.updateAmount(this.makePayment);
    }
  }

  async makePayment(cardPaymentAmount?: number) {
    const handleError = (err?: string) => {
      this.setFormError(err || Error.DEFAULT);
      this.resetLoading();
    };

    switch (this.selectedPaymentOption.value) {
      case PaymentMethod[PaymentMethodApi.DEBIT_CREDIT_CARD]:
        if (
          this.creditCardStore &&
          cardPaymentAmount &&
          this.paymentId &&
          this.order
        ) {
          await this.creditCardStore.sendCardPaymentRequest(
            cardPaymentAmount,
            this.paymentId,
            this.order,
            handleError,
          );
        } else {
          handleError();
        }
        break;
      case PaymentMethod.CASH_CHECK:
        if (this.cashCheckStore && this.paymentId) {
          await this.cashCheckStore.sendCashPendingRequest(
            this.paymentId,
            handleError,
          );
        } else {
          handleError();
        }
        break;
      case PaymentMethod[PaymentMethodApi.BANK_TRANSFER]:
        if (this.bankTransferStore && this.paymentId) {
          await this.bankTransferStore.sendPlaidRequest(
            this.paymentId,
            handleError,
            cardPaymentAmount,
          );
        } else {
          handleError();
        }
        break;
      default:
        this.resetLoading();
    }
  }

  get isPayPalMethod() {
    return this.selectedPaymentOption.value === PaymentMethod.PAYPAL;
  }

  get isPaymentAmount() {
    return !!formatUSDMoneyToCents(this.customValue);
  }

  get amount() {
    return this.payment.amount || 0;
  }

  get paymentId() {
    return this.payment.id;
  }

  get remainingBalance() {
    return this.payment.currentBalance || this.order.totalAmount || 0;
  }

  get isCustomValueValid() {
    const centsValue = formatUSDMoneyToCents(this.customValue);
    return Boolean(centsValue && centsValue > 0);
  }

  get isFullPayment() {
    return this.payment.paymentType === PaymentTypeApi.FULL;
  }

  get isPaymentFormInvalid() {
    return (
      (this.selectedPaymentOption.value === PaymentMethod.CASH_CHECK &&
        (!this.cashCheckStore || !this.cashCheckStore.isFormValid)) ||
      (this.selectedPaymentOption.value ===
        PaymentMethod[PaymentMethodApi.DEBIT_CREDIT_CARD] &&
        (!this.creditCardStore || !this.creditCardStore.isFormValid)) ||
      this.isLoading ||
      !this.customValue ||
      !this.isCustomValueValid
    );
  }

  get shouldShowMethodsRadioboxes() {
    return this.paymentMethodOptions.length > 1;
  }

  get shouldShowBillingAddressCheckbox() {
    return (
      this.creditCardStore &&
      this.selectedPaymentOption.apiValue === PaymentMethodApi.DEBIT_CREDIT_CARD
    );
  }

  get shouldShowBillingAddressForm() {
    return (
      this.shouldShowBillingAddressCheckbox &&
      !this.creditCardStore?.isSameBillingAddress
    );
  }

  async sendPatchAmountUpdate(updatedAmount: number) {
    const request = {
      id: this.paymentId,
      pending_amount: updatedAmount,
    };

    api
      .patch('/payments', request)
      .then(() => {
        this.makePayment();
      })
      .catch((err) => {
        console.error(err);
        this.resetLoading();
        this.setFormError(Error.DEFAULT);
      });
  }
}

export default PaymentFormStore;
