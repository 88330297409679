import React from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/icon-arrow.svg';
import { ReactComponent as ArrowDoubleIcon } from 'src/assets/icons/icon-arrow-double.svg';

import type { ArrowButtonProps } from './arrow-button.props';

import './arrow-button.scss';

const ArrowButton = ({
  ariaLabel,
  className,
  direction,
  isDisabled,
  isDouble,
  onClick,
}: ArrowButtonProps) => (
  <button
    className={classNames(
      `arrow-button arrow-button_${direction}`,
      className,
      isDisabled && 'arrow-button_disabled',
    )}
    aria-label={ariaLabel}
    disabled={isDisabled}
    onClick={onClick}
    type="button"
  >
    {isDouble ? (
      <ArrowDoubleIcon className="arrow-button__icon" />
    ) : (
      <ArrowIcon className="arrow-button__icon" />
    )}
  </button>
);

export default ArrowButton;
