import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import {
  CustomScrollbar,
  Footer,
  Loader,
} from 'src/components/common-components';
import PaymentForm from './components/payment-form';

import ordersStore from 'src/stores/orders-store';
import PaymentPageStore from 'src/stores/payment-page-store';
import processingStore from 'src/stores/processing-store';

import { AppRoute, ProcessingStatus } from 'src/constants';

import './payment-page.scss';

const PaymentPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [paymentPageStore] = useState(() => new PaymentPageStore(id));
  const { forbidAccess, formStore, isFormCanBeShown } = paymentPageStore;

  useEffect(() => {
    if (id) {
      ordersStore.init(id);
    }

    return () => {
      processingStore.clearStore();
    };
  }, [id]);

  if (forbidAccess) {
    return <Redirect to={AppRoute.ROOT} />;
  }

  return (
    <CustomScrollbar width="100%" height="100%">
      <div className="payment-page">
        {isFormCanBeShown && formStore ? (
          <PaymentForm
            className="payment-page__form"
            pageStore={paymentPageStore}
            formStore={formStore}
          />
        ) : (
          <Loader />
        )}
        <Footer
          className={classNames(
            processingStore.status === ProcessingStatus.PAYMENT_SUCCESS &&
              'payment-page__fixed-footer',
          )}
        />
      </div>
    </CustomScrollbar>
  );
});

export default PaymentPage;
