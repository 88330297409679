import React from 'react';
import classNames from 'classnames';

import type { DataListProps } from './data-list.props';

import './data-list.scss';

const DataList = ({ className, children, isSpaced, title }: DataListProps) => (
  <div
    className={classNames(
      'data-list',
      isSpaced && 'data-list_spaced',
      className,
    )}
  >
    {title && <h3 className="data-list__title">{title}</h3>}
    {children}
  </div>
);

export default DataList;
