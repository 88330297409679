import React from 'react';
import classNames from 'classnames';

import {
  AFSLogo,
  ExternalLink,
  FooterLinks,
} from 'src/components/common-components';

import type { FooterProps } from './footer.props';

import './footer.scss';

const Footer = ({ className }: FooterProps) => (
  <footer className={classNames('footer', className)}>
    <FooterLinks className="footer__links" />
    <ExternalLink
      className="footer__logo"
      href="https://allfuneral.com/"
      title="All Funeral Services"
    >
      <AFSLogo className="footer__logo-image" />
    </ExternalLink>
  </footer>
);

export default Footer;
