import { useCallback, useState } from 'react';

export default function usePopup() {
  const [isPopupShown, setIsPopupShown] = useState(false);

  const showPopup = useCallback(() => {
    setIsPopupShown(true);
  }, []);

  const hidePopup = useCallback(() => {
    setIsPopupShown(false);
  }, []);

  const togglePopup = useCallback(
    () => setIsPopupShown((prevState) => !prevState),
    [],
  );

  return {
    hidePopup,
    isPopupShown,
    showPopup,
    togglePopup,
  };
}
