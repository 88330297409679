import React from 'react';
import classNames from 'classnames';

import DataListPairs from './components/data-list-pairs';
import DataList from '../data-list';

import type { DataListPairedProps } from './data-list-paired.props';

const DataListPaired = ({
  id,
  className,
  isSpaced,
  pairs,
  pairsList,
  title,
}: DataListPairedProps) => (
  <DataList
    className={classNames('data-list_paired', className)}
    isSpaced={isSpaced}
    title={title}
  >
    {pairsList ? (
      <div className="data-list__pairs-wrapper">
        {pairsList.map((sectionPairs, idx) => (
          <DataListPairs
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            id={`${id}-${idx}`}
            pairs={sectionPairs}
          />
        ))}
      </div>
    ) : (
      pairs && <DataListPairs id={id} pairs={pairs} />
    )}
  </DataList>
);

export default DataListPaired;
