import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'src/components/ui-components';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderType } from 'src/constants';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { OrderActionsProps } from './order-actions.props';

import './order-actions.scss';

const OrderActions = observer(({ id, type, payment }: OrderActionsProps) => {
  const { getIsNextPaymentDisabled, setSelectedOrderId } = ordersStore;

  const handleSignInButtonClick = () => {
    setSelectedOrderId(id);
  };

  const renderActions = () => {
    switch (type) {
      case OrderType.SIGNING:
        return (
          <>
            <div className="order-actions__info">
              <span className="order-actions__title">
                Your signature is required
              </span>
            </div>
            <Button
              className="order-actions__button"
              label="Sign Now"
              tag="link"
              href={`${AppRoute.SIGNING}/${id}`}
              onClick={handleSignInButtonClick}
              theme="filled"
              size="oversize"
            />
          </>
        );
      case OrderType.ACTIVE:
        return (
          <>
            <div className="order-actions__info">
              <span className="order-actions__title order-actions__title_payment">
                Next Payment:
              </span>
              <span className="order-actions__value">
                {formatAsUSDOptionalCents(payment || 0)}
              </span>
            </div>
            <Button
              className="order-actions__button"
              isDisabled={getIsNextPaymentDisabled(id)}
              label="Pay Now"
              tag="link"
              href={`${AppRoute.PAYMENT}/${id}`}
              theme="filled"
              size="oversize"
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return <div className="order-actions__container">{renderActions()}</div>;
});

export default OrderActions;
