import { makeAutoObservable, reaction } from 'mobx';

import processingStore from './processing-store';

import api from 'src/api';
import { ApiStatus, STORAGE_JWT_KEY } from 'src/constants';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils';

const decodeRawToken = (tokenRaw: string) => {
  try {
    const payload = tokenRaw.split('.')[1];
    const decoded = atob(payload);

    return JSON.parse(decoded);
  } catch (e) {
    console.error('Failed to process token');
    return '';
  }
};

class GlobalStore {
  isLoading: boolean = true;

  token = getLocalStorageItem(STORAGE_JWT_KEY);

  user: string = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.token,
      (token) => token && this.init(),
    );
  }

  init() {
    this.checkToken();
    this.setUserFromToken();
    this.setIsLoading(false);
  }

  checkToken() {
    const TOKEN = process.env.REACT_APP_TOKEN;

    if (!this.token && TOKEN) {
      this.setToken(TOKEN);
    }
  }

  logOut() {
    removeLocalStorageItem(STORAGE_JWT_KEY);
    this.clearStore();
    processingStore.clearStore();
  }

  async loginByToken(token: string, purchaseId: string) {
    if (this.token) {
      removeLocalStorageItem(STORAGE_JWT_KEY);
      this.clearStore();
    }

    try {
      const result = await api.post('/personal_area/loginByToken', {
        token,
        purchaseId,
      });

      if (result.status === ApiStatus.SUCCESS) {
        const newToken = result.headers?.authorization || '';
        this.setToken(newToken);
      }
    } catch (err: any) {
      console.error('ERROR: ', err);
      this.setIsLoading(false);
    }
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setToken(token: string) {
    this.token = token;
    setLocalStorageItem(STORAGE_JWT_KEY, token);
  }

  setUserFromToken() {
    if (this.token) {
      const { firstname = '', lastname = '' } =
        decodeRawToken(this.token)?.pld?.purchaser ?? {};

      this.user = `${firstname} ${lastname}`;
    }
  }

  clearStore() {
    this.setToken('');
    this.user = '';
  }
}

export default new GlobalStore();
