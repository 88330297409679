import baseAxios from 'axios';

import globalStore from 'src/stores/global-store';

import { ApiStatus } from 'src/constants';

interface IErrorResponse {
  status?: number;
}

const api = baseAxios.create({
  baseURL: window.API_URL,
});

api.interceptors.request.use(
  (options) => {
    const { token } = globalStore;
    if (token) {
      options.headers!.Authorization = token;
    }
    return options;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = (error?.response as IErrorResponse) || {};

    if (status === ApiStatus.UNAUTHORIZED) {
      globalStore.logOut();
    }

    return Promise.reject(error);
  },
);

export default api;
