import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import type { LoaderProps } from './loader.props';

import './loader.scss';

const DEBOUNCE_INTERVAL = 300;

const Loader = ({ className, noDebounce }: LoaderProps) => {
  const [showComponent, setShowComponent] = useState(noDebounce);

  useEffect(() => {
    const timer = setTimeout(() => setShowComponent(true), DEBOUNCE_INTERVAL);
    return () => clearTimeout(timer);
  }, []);

  return showComponent ? (
    <div className={classNames('loader', className)}>
      <svg className="loader__spinner" viewBox="0 0 50 50">
        <circle
          className="loader__spinner-path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    </div>
  ) : (
    <></>
  );
};

export default Loader;
