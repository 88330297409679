import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import type { PopupProps } from './popup.props';

import './popup.scss';

const Popup = ({ children, className }: PopupProps) => {
  const el = useMemo(() => document.createElement('div'), []);

  const InnerComponent = () => (
    <div className={classNames('popup', className)}>{children}</div>
  );

  useEffect(() => {
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(<InnerComponent />, el);
};

export default Popup;
