import React from 'react';
import { observer } from 'mobx-react-lite';

import { Record } from 'src/components/common-components';

import ordersStore from 'src/stores/orders-store';

import {
  formatAsUSDOptionalCents,
  formatDate,
  getLot,
  getOrderAdditionalLabel,
} from 'src/utils';

import './order-info.scss';

const OrderInfo = observer(() => {
  const { selectedOrder, selectedOrderRemainingBalance } = ordersStore;

  if (!selectedOrder) {
    return null;
  }

  const { agreementNumber, createdAt, inventoryLot, lots, payment } =
    selectedOrder;

  const additionalInfo = getOrderAdditionalLabel(selectedOrder);

  return (
    <div className="order-info">
      <h1 className="order-info__header">
        Agreement <br className="order-info__br" />
        {agreementNumber}
      </h1>
      <div className="order-info__content">
        <div className="order-info__row order-info__row_property">
          {inventoryLot ? (
            <Record name="Property" value={getLot(inventoryLot)} />
          ) : !!lots?.length ? (
            lots && <Record name="Property" value={lots?.map(getLot)} />
          ) : (
            <></>
          )}
          {additionalInfo && (
            <span className="order-info__additional-info">
              {getOrderAdditionalLabel(selectedOrder)}
            </span>
          )}
        </div>
        <div className="order-info__row">
          <Record name="Date" value={formatDate(createdAt)} />
          <Record name="Total cost" value={payment.total} />
          <Record
            name="Remaining balance"
            value={formatAsUSDOptionalCents(selectedOrderRemainingBalance)}
          />
        </div>
      </div>
    </div>
  );
});

export default OrderInfo;
