import { memo } from 'react';
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Loader } from 'src/components/common-components';

import { PAYPAL_CLIENT_ID } from 'src/constants';
import type { PaypalButtonProps } from './paypal-button.props';

import './paypal-button.scss';

const PaypalButton = observer(
  ({ isDisabled, isVisible, paypalStore, onCancel }: PaypalButtonProps) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const { handleApprove, handleCreateOrder, handleError } = paypalStore;

    return (
      <div
        className={classNames(
          'paypal-button',
          !isVisible && 'paypal-button_hidden',
        )}
      >
        {isPending ? <Loader /> : null}
        <PayPalButtons
          disabled={isDisabled}
          forceReRender={[
            handleApprove,
            handleCreateOrder,
            handleError,
            onCancel,
          ]}
          style={{
            color: 'black',
            height: 52,
            label: 'pay',
            layout: 'horizontal',
            tagline: true,
          }}
          createOrder={handleCreateOrder}
          onApprove={handleApprove}
          onCancel={onCancel}
          onError={handleError}
        />
      </div>
    );
  },
);

const PaypalButtonWrapper = memo((props: PaypalButtonProps) => (
  <PayPalScriptProvider
    options={{ clientId: PAYPAL_CLIENT_ID, disableFunding: 'credit,card' }}
  >
    <PaypalButton {...props} />
  </PayPalScriptProvider>
));

export default PaypalButtonWrapper;
