import React from 'react';
import classNames from 'classnames';

import type { LabelProps } from './label.props';

import './label.scss';

function Label({
  label,
  id,
  isFloated,
  className,
  theme,
  ...props
}: LabelProps) {
  const classes = classNames('label', className, theme && `label_${theme}`, {
    label_floated: isFloated,
  });

  return label ? (
    <label className={classes} htmlFor={id} {...props}>
      {label}
    </label>
  ) : null;
}

export default Label;
