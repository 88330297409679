import React from 'react';
import classNames from 'classnames';

import { ExternalLink } from 'src/components/common-components';

import { ABOUT_LINK, AppRoute } from 'src/constants';
import type { FooterLinksProps } from './footer-links.props';

import './footer-links.scss';

const FooterLinks = ({ className, page = 'any' }: FooterLinksProps) => (
  <div
    className={classNames(
      'footer-links',
      `footer-links_page_${page}`,
      className,
    )}
  >
    <ExternalLink className="footer-links__item" href={ABOUT_LINK}>
      Cemetery Website
    </ExternalLink>
    <ExternalLink className="footer-links__item" href={AppRoute.POLICY}>
      Privacy Policy
    </ExternalLink>
    <ExternalLink className="footer-links__item" href={AppRoute.TERMS}>
      Terms of Use
    </ExternalLink>
  </div>
);

export default FooterLinks;
