import React from 'react';
import classNames from 'classnames';

import { ReactComponent as LogoImage } from 'src/assets/icons/logo-afs-image.svg';
import { ReactComponent as LogoText } from 'src/assets/icons/logo-afs-text.svg';

import type { AFSLogoProps } from './afs-logo.props';

import './afs-logo.scss';

const AFSLogo = ({ className, page = 'any' }: AFSLogoProps) => (
  <div className={classNames('afs-logo', `afs-logo_page_${page}`, className)}>
    <LogoImage className="afs-logo__icon  afs-logo__icon_image" />
    <LogoText className="afs-logo__icon  afs-logo__icon_text" />
  </div>
);

export default AFSLogo;
