import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Footer, Loader } from 'src/components/common-components';
import { Button } from 'src/components/ui-components';
import OrderSummary from './components/order-summary';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderStatus } from 'src/constants';
import { openSignInLink } from 'src/utils';

import './signing-page.scss';

const SigningPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, selectedOrder, selectedOrderId } = ordersStore;

  useEffect(() => {
    if (id && !selectedOrder) {
      ordersStore.init(id);
    }
  }, [id, selectedOrder]);

  if (
    (!isLoading && !selectedOrder && selectedOrderId) ||
    (!isLoading && selectedOrder?.status !== OrderStatus.PENDING_SIGNATURE)
  ) {
    return <Redirect to={AppRoute.DASHBOARD} />;
  }

  const renderSummary = () => {
    if (!selectedOrder) {
      return null;
    }

    return <OrderSummary order={selectedOrder} />;
  };

  if (isLoading || !selectedOrder) {
    return <Loader />;
  }

  return (
    <div className="signing-page">
      <div className="signing-page__wrapper">
        <h1 className="signing-page__title">
          Agreement{' '}
          <span className="signing-page__title-number">
            {selectedOrder?.agreementNumber}
          </span>
        </h1>
        <div className="signing-page__content">{renderSummary()}</div>
      </div>
      <div className="signing-page__action">
        <p className="signing-page__action-text">
          Clicking will take you to the DocuSign
        </p>
        <Button
          className="signing-page__action-button"
          label="Sign Purchase Agreement"
          theme="filled"
          size="oversize"
          onClick={() => openSignInLink(selectedOrderId)}
        />
      </div>
      <Footer />
    </div>
  );
});

export default SigningPage;
