import {
  DiscountType,
  InstallmentType,
  LotType,
  PaymentType,
  Rights,
} from 'src/constants';
import {
  formatAsUSDOptionalCents,
  formatDate,
  sumServicesAmount,
} from 'src/utils';
import type {
  IApiInventoryLot,
  IApiListOrder,
  IApiLot,
  IApiOrder,
  IApiPerson,
  IDiscount,
  IListOrder,
  ILocation,
  ILot,
  IOrder,
  IPerson,
  IService,
} from 'src/interfaces';

const calcTaxesValue = (taxPercent: number, totalSum: number): string => {
  if (typeof taxPercent !== 'number' || typeof totalSum !== 'number') {
    return '-';
  }

  return formatAsUSDOptionalCents((totalSum * taxPercent) / 100);
};

const getAddress = (location: ILocation = {}) => {
  const building = location.building ? `${location.building} ` : '';
  const street = location.street ? `${location.street}, ` : '';
  const room = location.room ? `#${location.room}, ` : '';
  const city = location.city ? `${location.city}, ` : '';
  const state = location.state ? `${location.state} ` : '';
  const zip = location.zip ? `${location.zip}` : '';
  return `${building}${street}${room}${city}${state}${zip}`;
};

const getPhone = (phone = '') =>
  `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;

const getPersonalData = (person: IApiPerson): IPerson => ({
  name: `${person.firstname} ${person.lastname}`,
  address:
    person.address?.formatted_address ||
    (person.address ? getAddress(person.address) : ''),
  rawAddress: person.address,
  dateOfBirth: person.date_of_birth
    ? formatDate(person.date_of_birth, 'MM/DD/YYYY')
    : '',
  phone: person.phone ? getPhone(person.phone) : '',
  email: person.email || '',
});

const getPaymentType = (apiType: string) => PaymentType[apiType] || '';
const getInstallmentType = (apiType: string) => InstallmentType[apiType] || '';

const getDiscountCentsSum = (discount: IDiscount, totalSum: number) => {
  const isPercent = discount.type === DiscountType.PERCENT;
  return isPercent ? (totalSum * discount.amount) / 100 : discount.amount;
};

const getFormattedDiscount = (
  discount: IDiscount,
  lotsAndServicesSum: number,
) => {
  const isPercent = discount.type === DiscountType.PERCENT;
  const fixedValue = isPercent
    ? formatAsUSDOptionalCents((lotsAndServicesSum * discount.amount) / 100)
    : formatAsUSDOptionalCents(discount.amount);
  return {
    value: isPercent ? `${discount.amount}% (${fixedValue})` : fixedValue,
    subTotal: formatAsUSDOptionalCents(lotsAndServicesSum),
  };
};

const transformServiceText = (item: IService) => ({
  ...item,
  service: {
    ...item.service,
    name:
      item.service.name.charAt(0).toUpperCase() +
      item.service.name.slice(1).toLowerCase(),
  },
});

export const transformInventoryLotFromApi = (lot: IApiLot) => ({
  bank: lot.bank?.name,
  block: lot.block,
  number: lot.no.toUpperCase(),
  mausoleum: lot.mausoleum?.name,
  price: formatAsUSDOptionalCents(lot.price),
  ridge: lot.ridge,
  right: Rights[lot.right] || '',
  row: lot.row,
  section: lot.section?.no || '',
  tier: lot.tier,
  type: LotType[lot.type],
});

export const transformLotFromApi = (lot: IApiLot | IApiInventoryLot): ILot => ({
  bank: lot.bank?.name,
  block: lot.block,
  number: lot.no.toUpperCase(),
  mausoleum: lot.mausoleum?.name,
  price: 'price' in lot ? formatAsUSDOptionalCents(lot.price) : '',
  ridge: lot.ridge,
  right: Rights[lot.right] || '',
  row: lot.row,
  section: lot.section?.no || '',
  tier: lot.tier,
  type: LotType[lot.type],
});

export function transformOrderFromApi(apiOrderData: IApiListOrder): IListOrder;
export function transformOrderFromApi(apiOrderData: IApiOrder): IOrder;
export function transformOrderFromApi(
  apiOrderData: IApiOrder | IApiListOrder,
): IOrder | IListOrder {
  const lotsPriceCentsSum = apiOrderData.lots?.length
    ? apiOrderData.lots.reduce((sum, lot) => sum + lot.price, 0)
    : 0;
  const servicesPriceCentsSum = apiOrderData.services?.length
    ? sumServicesAmount(apiOrderData.services)
    : 0;
  const discountTotalCentsSum = apiOrderData.discount
    ? getDiscountCentsSum(
        apiOrderData.discount,
        lotsPriceCentsSum + servicesPriceCentsSum,
      )
    : 0;
  const discountLotsCentsSum = apiOrderData.discount
    ? getDiscountCentsSum(apiOrderData.discount, lotsPriceCentsSum)
    : 0;

  return {
    id: apiOrderData.id,
    agreementNumber: apiOrderData.no,
    contractDate: apiOrderData.contract_date,
    createdAt: apiOrderData.created_at,
    documents: apiOrderData.documents ?? [],
    services: apiOrderData.services
      ? apiOrderData.services.map(transformServiceText)
      : [],
    status: apiOrderData.status,
    cemetery: {
      name: apiOrderData.cemetery?.name,
      address:
        typeof apiOrderData.cemetery?.organization === 'string'
          ? ''
          : apiOrderData.cemetery?.organization?.location?.formatted_address ??
            getAddress(apiOrderData.cemetery?.organization?.location),
      phone:
        typeof apiOrderData.cemetery?.organization === 'string'
          ? ''
          : getPhone(apiOrderData.cemetery?.organization?.phone),
      email:
        typeof apiOrderData.cemetery?.organization === 'string'
          ? ''
          : apiOrderData.cemetery?.organization?.email,
    },
    lots: apiOrderData.lots?.map(transformLotFromApi) ?? [],
    inventoryLot: apiOrderData.inventory_lot
      ? transformLotFromApi(apiOrderData.inventory_lot)
      : undefined,
    deedOwners:
      apiOrderData.deed_owners?.map((owner: IApiPerson) =>
        getPersonalData(owner),
      ) || [],
    purchasers: apiOrderData.purchasers?.map(getPersonalData) || [],
    payment: {
      discount:
        apiOrderData.discount &&
        (!!apiOrderData.lots || !!apiOrderData.inventory_lot)
          ? getFormattedDiscount(
              apiOrderData.discount,
              lotsPriceCentsSum + servicesPriceCentsSum,
            )
          : null,
      total: formatAsUSDOptionalCents(apiOrderData.total_amount),
      type: getPaymentType(apiOrderData.payment_type),
      downPayment: apiOrderData.installment?.down_payment
        ? formatAsUSDOptionalCents(apiOrderData.installment.down_payment)
        : '',
      monthlyPayment: apiOrderData.installment?.monthly_payment
        ? formatAsUSDOptionalCents(apiOrderData.installment.monthly_payment)
        : '',
      paymentFrequency: apiOrderData.installment?.type
        ? getInstallmentType(apiOrderData.installment.type)
        : '',
      firstRegularPaymentDate: apiOrderData.installment?.first_payment_date,
      goodsAndServicesTax: apiOrderData.goods_and_services_tax
        ? calcTaxesValue(
            apiOrderData.goods_and_services_tax,
            lotsPriceCentsSum + servicesPriceCentsSum - discountTotalCentsSum,
          )
        : undefined,
      perpetualCare: apiOrderData.perpetual_care
        ? apiOrderData.inventory_lot || !apiOrderData.lots?.length
          ? '$0'
          : calcTaxesValue(
              apiOrderData.perpetual_care,
              lotsPriceCentsSum - discountLotsCentsSum,
            )
        : undefined,
    },
    needSignAgreement: apiOrderData.need_sign_agreement ?? true,
    onlyMceAgreement: apiOrderData.only_mce_agreement || false,
    stripeAccount:
      typeof apiOrderData.cemetery?.organization === 'object'
        ? apiOrderData.cemetery?.organization?.stripe_account
        : undefined,
    totalAmount: apiOrderData.total_amount || 0,
    type: apiOrderData.type,
  };
}

export const transformOrdersFromApi = (apiOrders: IApiListOrder[]) => [
  ...apiOrders.map((order) => transformOrderFromApi(order)),
];
