import React from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from 'src/components/common-components';

import processingStore from 'src/stores/processing-store';

import './processing-page.scss';

const ProcessingPage = observer(() => (
  <div className="processing-page">
    <Header />
    <div className="processing-page__content">
      <div className="processing-page__spinner">
        <div className="processing-page__spinner-icon" />
      </div>
      <p className="processing-page__text">{processingStore.message}</p>
      <p className="processing-page__text">Please wait</p>
    </div>
  </div>
));

export default ProcessingPage;
