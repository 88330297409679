import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { ButtonIconProps } from './button-icon.props';

import './button-icon.scss';

const ButtonIcon = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonIconProps
>(
  (
    {
      ariaLabel,
      className,
      icon,
      isDisabled,
      onClick,
      size = 'xs',
      tag = 'button',
      target,
      to = '',
      type = 'button',
      color = 'accent',
      ...props
    },
    ref,
  ) => {
    const buttonClasses = classNames(
      'button-icon',
      size === 'xs' && 'button-icon_size_xs',
      size === 'sm' && 'button-icon_size_sm',
      size === 'md' && 'button-icon_size_md',
      size === 'lg' && 'button-icon_size_lg',
      tag === 'button' || 'button-icon_link-tag-reset',
      isDisabled && 'button-icon_disabled',
      color === 'accent' && 'button-icon_color_accent',
      color === 'primary' && 'button-icon_color_primary',
      className,
    );

    const renderComponent = () => {
      switch (tag) {
        case 'button':
          return (
            <button
              className={buttonClasses}
              onClick={onClick}
              aria-label={ariaLabel}
              type={type}
              disabled={isDisabled}
              ref={ref as React.ForwardedRef<HTMLButtonElement>}
              {...props}
            >
              {icon}
            </button>
          );
        case 'link':
          return (
            <Link
              className={buttonClasses}
              onClick={onClick}
              aria-label={ariaLabel}
              to={to}
              target={target || '_self'}
              ref={ref as React.ForwardedRef<HTMLAnchorElement>}
              {...props}
            >
              {icon}
            </Link>
          );
        case 'a':
          return (
            <a
              className={buttonClasses}
              href={to}
              onClick={onClick}
              rel="noopener noreferrer"
              target={target || '_blank'}
              ref={ref as React.ForwardedRef<HTMLAnchorElement>}
              {...props}
            >
              {icon}
            </a>
          );
        default:
          return null;
      }
    };
    return <>{renderComponent()}</>;
  },
);

export default ButtonIcon;
