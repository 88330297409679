import { makeAutoObservable, reaction } from 'mobx';

import globalStore from 'src/stores/global-store';
import PaymentFormStore from 'src/stores/payment-form/payment-form-store';

import { transformOrderFromApi } from 'src/adapters/orders';
import { transformPaymentsFromApi } from 'src/adapters/payments';
import api from 'src/api';
import { ApiStatus, PaymentStatus, PaymentStatusApi } from 'src/constants';
import type { IApiSinglePayment, IOrder, ISinglePayment } from 'src/interfaces';

class PaymentPageStore {
  currentPayment: ISinglePayment | null = null;
  formStore: PaymentFormStore | null = null;
  forbidAccess: boolean = false;
  isLoading: boolean = false;
  isMorePaymentsAhead?: boolean;
  isMonthlyPayment: boolean = false;
  orderId: string;
  orderData: IOrder | null = null;
  orderPayments: ISinglePayment[] = [];

  init() {
    this.loadOrderData();
  }

  constructor(orderId: string) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.orderId = orderId;
    this.init();

    reaction(
      () => [this.orderData, this.currentPayment],
      () => {
        if (this.orderData && this.currentPayment) {
          this.formStore = new PaymentFormStore(
            this.orderData,
            this.currentPayment,
          );
        } else {
          this.formStore = null;
        }
      },
    );
  }

  checkIsMonthlyPayment(payments: IApiSinglePayment[]) {
    const isMonthlyPayment = payments.some(
      (item) => item.status !== PaymentStatusApi.UNPAID,
    );
    this.setIsMonthlyPayment(isMonthlyPayment);
  }

  getCurrentPayment(payments: ISinglePayment[]) {
    const isOrderPendingPayment = payments.find(
      (item) => item.status === PaymentStatus[PaymentStatusApi.PENDING],
    );

    if (isOrderPendingPayment) {
      this.setForbidAccess(true);
      return;
    }

    const nextPayment = payments.find(
      (item) => item.status === PaymentStatus[PaymentStatusApi.UNPAID],
    );

    if (nextPayment) {
      this.setCurrentPayment(nextPayment);
    } else {
      this.setForbidAccess(true);
    }
  }

  setForbidAccess(forbidAccess: boolean) {
    this.forbidAccess = forbidAccess;
  }

  setCurrentPayment(payment: ISinglePayment) {
    this.currentPayment = payment;
  }

  setIsMorePaymentsAhead(isMorePaymentsAhead: boolean) {
    this.isMorePaymentsAhead = isMorePaymentsAhead;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsMonthlyPayment(isMonthlyPayment: boolean) {
    this.isMonthlyPayment = isMonthlyPayment;
  }

  setOrderData(data: IOrder) {
    this.orderData = data;
  }

  setOrderPayments(payments: ISinglePayment[]) {
    this.orderPayments = payments;
  }

  checkIsMorePaymentsAhead(payments: IApiSinglePayment[]) {
    const unpaidPayments = payments.filter(
      (item) => item.status === PaymentStatusApi.UNPAID,
    );

    this.setIsMorePaymentsAhead(unpaidPayments.length > 1);
  }

  get isFormCanBeShown() {
    return (
      this.orderPayments.length &&
      this.currentPayment &&
      !this.isLoading &&
      this.formStore
    );
  }

  async loadOrderData() {
    if (!this.orderId || !globalStore.token) {
      return;
    }

    this.setIsLoading(true);
    try {
      const result = await api.get(`/purchases/${this.orderId}`);

      if (result.data) {
        const orderData = transformOrderFromApi(result.data);
        this.setOrderData(orderData);
        this.loadOrderPayments(orderData);
      } else {
        this.setIsLoading(false);
      }
    } catch (err) {
      console.error('err', err);
      this.setIsLoading(false);
    }
  }

  async loadOrderPayments(orderData: IOrder) {
    this.setIsLoading(true);
    const params = {
      purchaseId: this.orderId,
      sort: JSON.stringify({ due_date: 'asc' }),
    };

    try {
      const result = await api.get('/payments/', {
        params,
      });

      if (!result?.data || result?.data?.length === 0) {
        this.setForbidAccess(true);
      }

      if (result.status === ApiStatus.SUCCESS) {
        const transformedData = transformPaymentsFromApi(
          result.data,
          orderData.totalAmount || 0,
        );
        this.checkIsMonthlyPayment(result.data);
        this.checkIsMorePaymentsAhead(result.data);
        this.setOrderPayments(transformedData);
        this.getCurrentPayment(transformedData);
      }
    } catch (err) {
      console.error('err', err);
      this.setForbidAccess(true);
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default PaymentPageStore;
