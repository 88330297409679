import { useState } from 'react';

export default function useToggle(initialValue: boolean) {
  const [isActive, setIsActive] = useState(initialValue);

  const toggle = () => {
    setIsActive((prevState) => !prevState);
  };

  return { isActive, toggle };
}
