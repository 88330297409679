import { PurchaseType } from 'src/constants';
import type { IOrder } from 'src/interfaces';

export const getOrderAdditionalLabel = (order: IOrder) => {
  if (order.type === PurchaseType.SCATTERING) {
    return 'Scattering';
  }

  return '';
};
