import dayjs from 'dayjs';

const DAYS_TO_EXPIRE = 10;
/**
 * Check if dueDate is expiring
 * @param  {string} date {dueDate}
 * @return {boolean} {is less than expire period}
 */
export function getIsDateExpiring(date: string) {
  const today = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();
  return dayjs(date).diff(today, 'day') < DAYS_TO_EXPIRE;
}

export function formatDate(date?: string, format: string = 'MMM DD, YYYY') {
  return dayjs(date).format(format);
}
