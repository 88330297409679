import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Button } from 'src/components/ui-components';

import type { PaymentFormActionsProps } from './payment-form-actions.props';

import './payment-form-actions.scss';

const PaymentFormActions = observer(
  ({ formStore }: PaymentFormActionsProps) => {
    const history = useHistory();
    const { isPaymentFormInvalid, isPayPalMethod, handlePaymentButtonClick } =
      formStore;

    return (
      <div
        className={classNames(
          'payment-form-actions',
          isPayPalMethod && 'payment-form-actions_paypal',
        )}
      >
        <Button
          className="payment-form-actions__button payment-form-actions__button_cancel"
          label="Cancel"
          onClick={history.goBack}
          theme="outlined"
          size="oversize"
        />
        {!isPayPalMethod && (
          <Button
            className="payment-form-actions__button payment-form-actions__button_payment"
            isDisabled={isPaymentFormInvalid}
            onClick={handlePaymentButtonClick}
            label="Make a Payment"
            theme="filled"
            size="oversize"
          />
        )}
      </div>
    );
  },
);

export default PaymentFormActions;
