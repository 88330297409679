import React from 'react';
import classNames from 'classnames';

import { ArrowButton } from 'src/components/ui-components';

import type { PaginationProps } from './pagination.props';

import './pagination.scss';

const Pagination = ({
  className,
  isFirstPage,
  isLastPage,
  onFirstPageButtonClick,
  onLastPageButtonClick,
  onPreviousPageButtonClick,
  onNextPageButtonClick,
  startListCount,
  endListCount,
  totalDataCount,
}: PaginationProps) => (
  <div className={classNames('pagination', className)}>
    <div className="pagination__buttons">
      <ArrowButton
        className="pagination__button"
        ariaLabel="To first page"
        direction="left"
        isDisabled={isFirstPage}
        isDouble
        onClick={onFirstPageButtonClick}
      />
      <ArrowButton
        className="pagination__button"
        ariaLabel="To previous page"
        direction="left"
        isDisabled={isFirstPage}
        onClick={onPreviousPageButtonClick}
      />
    </div>
    <div className="pagination__numbers">
      {`${startListCount}${
        startListCount !== endListCount ? `-${endListCount}` : ``
      }`}
      &nbsp;of&nbsp;{totalDataCount}
    </div>
    <div className="pagination__buttons">
      <ArrowButton
        className="pagination__button"
        ariaLabel="To next page"
        direction="right"
        isDisabled={isLastPage}
        onClick={onNextPageButtonClick}
      />
      <ArrowButton
        className="pagination__button"
        ariaLabel="To last page"
        direction="right"
        isDisabled={isLastPage}
        isDouble
        onClick={onLastPageButtonClick}
      />
    </div>
  </div>
);

export default Pagination;
