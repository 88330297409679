import { MutableRefObject, useLayoutEffect } from 'react';

import { Size } from 'src/constants';
import useWindowSize from 'src/hooks/use-window-size';

const HEADER_PADDINGS = 32;
const DEFAULT_LOGO_HEIGHT = 40;

export const useHeaderHeight = (
  ref: MutableRefObject<HTMLDivElement | null>,
) => {
  const { width } = useWindowSize();
  const logoHeight =
    (document.querySelector('.logo') as HTMLElement)?.offsetHeight ??
    DEFAULT_LOGO_HEIGHT;

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (width >= Size.TABLET_WIDTH) {
      ref.current.style.height = `${logoHeight + HEADER_PADDINGS}px`;
    } else {
      ref.current.removeAttribute('style');
    }
  }, [logoHeight, ref, width]);
};
