import { useEffect, useState } from 'react';

import type { ISinglePayment } from 'src/interfaces';

const PAGE_DATA_COUNT_LIMIT = 12;

const usePagination = (
  list: ISinglePayment[] = [],
  pageDataCountLimit = PAGE_DATA_COUNT_LIMIT,
) => {
  const [showedList, setShowedList] = useState(
    list.slice(0, pageDataCountLimit),
  );
  const [pageCount, setPageCount] = useState(1);
  const totalDataCount = list.length;
  const skipDataCount = (pageCount - 1) * pageDataCountLimit;
  const startListCount = showedList.length ? skipDataCount + 1 : 0;
  const endListCount = skipDataCount + showedList.length;
  const totalPages = Math.ceil(totalDataCount / pageDataCountLimit);
  const isShowPagination = totalDataCount > pageDataCountLimit;

  useEffect(() => {
    setShowedList(
      list.slice(
        (pageCount - 1) * pageDataCountLimit,
        pageCount * pageDataCountLimit,
      ),
    );
  }, [pageCount, pageDataCountLimit, list]);

  const incrementPage = () => {
    setPageCount((prevCount) => prevCount + 1);
  };

  const decrementPage = () => {
    setPageCount((prevCount) => prevCount - 1);
  };

  const setFirstPage = () => {
    setPageCount(1);
  };

  const setLastPage = () => {
    setPageCount(totalPages);
  };

  return {
    list: showedList,
    decrementPage,
    endListCount,
    incrementPage,
    isShowPagination,
    pageCount,
    setFirstPage,
    setLastPage,
    skipDataCount,
    startListCount,
    totalDataCount,
  };
};

export default usePagination;
