import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { DataListPaired, Loader, Tabs } from 'src/components/common-components';
import { PopupPaymentSuccess } from 'src/components/common-components/popups';
import { Checkbox, RadioboxList } from 'src/components/ui-components';
import BillingAddress from '../billing-address';
import InstallmentPaymentAmount from '../installment-payment-amount';
import InstalmentsList from '../instalments-list';
import PaymentDetails from '../payment-details';
import PaymentFormActions from '../payment-form-actions';
import PaymentMethodForm from '../payment-method-form';

import ordersStore from 'src/stores/orders-store';
import type PaymentPageStore from 'src/stores/payment-page-store';
import processingStore from 'src/stores/processing-store';

import { PaymentTypeApi, ProcessingStatus } from 'src/constants';
import usePopup from 'src/hooks/use-popup';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { IDataListPair } from 'src/interfaces';
import type { PaymentFormProps } from './payment-form.props';

import './payment-form.scss';

enum DetailsTab {
  PAYMENTS = 'Payment',
  INSTALMENT = 'Instalment Plan',
}

const PaymentForm = observer(
  ({ className, pageStore, formStore }: PaymentFormProps) => {
    const amountInputRef = useRef<HTMLInputElement>(null);
    const [activeDetailTab, setActiveDetailTab] = useState<DetailsTab>(
      DetailsTab.PAYMENTS,
    );
    const {
      currentPayment,
      isMorePaymentsAhead,
      orderData,
      orderPayments,
      orderId,
    } = pageStore;
    const {
      bankTransferStore,
      creditCardStore,
      cashCheckStore,
      paypalStore,
      formError,
      isFullPayment,
      isLoading: isFormLoading,
      isPaymentAmount,
      paymentMethodOptions,
      selectedPaymentOption,
      shouldShowBillingAddressCheckbox,
      shouldShowBillingAddressForm,
      shouldShowMethodsRadioboxes,
      handlePaymentOptionChange,
      resetLoading,
    } = formStore;
    const { status } = processingStore;
    const { isPopupShown, showPopup } = usePopup();

    useEffect(() => {
      if (paypalStore && amountInputRef.current) {
        paypalStore.setAmountInputRef(amountInputRef);
      }
    }, [amountInputRef, paypalStore]);

    useEffect(() => {
      if (status === ProcessingStatus.PAYMENT_SUCCESS) {
        ordersStore.init(orderId);
        showPopup();
      }

      return () => {
        resetLoading();
      };
    }, [status, orderId, resetLoading, showPopup]);

    if (!currentPayment) {
      return <Loader className="loader_position_overlay" />;
    }

    const getDetailsTab = (tabName: DetailsTab) => ({
      name: tabName,
      label: tabName,
      handleClick: () => setActiveDetailTab(tabName),
    });

    const renderDataList = () => {
      if (activeDetailTab === DetailsTab.PAYMENTS) {
        if (pageStore.orderPayments.length > 1 && !pageStore.isMonthlyPayment) {
          return (
            <div className="payment-form__order-data">
              <DataListPaired
                id="downPayment"
                className="payment-form__data-list"
                isSpaced
                pairs={getPaymentDetails('down', pageStore)}
              />
            </div>
          );
        }

        if (pageStore.orderPayments.length > 1 && pageStore.isMonthlyPayment) {
          return (
            <div className="payment-form__order-data">
              <DataListPaired
                id="regularPayment"
                className="payment-form__data-list"
                isSpaced
                pairs={getPaymentDetails('regular', pageStore)}
              />
            </div>
          );
        }
      }

      return <></>;
    };

    const isPaymentSocketProcess =
      processingStore.status === ProcessingStatus.PAYMENT_SUCCESS ||
      processingStore.status === ProcessingStatus.PAYMENT_WAITING;

    const isShadow = isFormLoading || isPaymentSocketProcess;

    return (
      <div className={classNames('payment-form', className)}>
        <h1 className="payment-form__title">
          <span className="payment-form__title-mobile">Make a Payment</span>
          <span className="payment-form__title-desktop">Payment Details</span>
        </h1>
        <PaymentDetails>
          {isFullPayment && (
            <div className="payment-form__order-data">
              <DataListPaired
                id="paymentFormDataList"
                className="payment-form__data-list"
                isSpaced
                pairs={getPaymentDetails('full', pageStore)}
              />
            </div>
          )}
          {!isFullPayment &&
            currentPayment.paymentType === PaymentTypeApi.INSTALLMENT && (
              <div className="payment-form__tabs-content">
                <Tabs
                  activeTab={activeDetailTab}
                  className="payment-form__tabs"
                  isTabsOversize
                  tabs={[
                    getDetailsTab(DetailsTab.PAYMENTS),
                    getDetailsTab(DetailsTab.INSTALMENT),
                  ]}
                />
                {activeDetailTab === DetailsTab.PAYMENTS && renderDataList()}
                {activeDetailTab === DetailsTab.INSTALMENT && (
                  <InstalmentsList payments={orderPayments} />
                )}
              </div>
            )}
        </PaymentDetails>
        {isFullPayment || (
          <InstallmentPaymentAmount
            className="payment-form__installment-payment-amount"
            ref={amountInputRef}
            isFirstPayment={!pageStore.isMonthlyPayment}
            formStore={formStore}
          />
        )}
        <div className="payment-form__method">
          <div className="payment-form__method-content">
            <h2 className="payment-form__method-title">Payment Method</h2>
            {shouldShowMethodsRadioboxes && (
              <RadioboxList
                className="payment-form__method-options"
                isDisabled={isFormLoading}
                listItems={paymentMethodOptions}
                name="paymentFormMethodOption"
                selectedItem={selectedPaymentOption}
                onChange={handlePaymentOptionChange}
              />
            )}
            <PaymentMethodForm
              bankTransferStore={bankTransferStore}
              cashCheckStore={cashCheckStore}
              creditCardStore={creditCardStore}
              paypalStore={paypalStore}
              isMorePaymentsAhead={!!isMorePaymentsAhead}
              isPaymentAmount={isPaymentAmount}
              loadingPayment={isFormLoading}
              paymentOption={selectedPaymentOption}
              stripeAccount={orderData?.stripeAccount}
            />
            {formError && <p className="payment-form__error">{formError}</p>}
          </div>
          {shouldShowBillingAddressCheckbox && !!creditCardStore && (
            <div className="payment-form__billing-address">
              <Checkbox
                className="payment-form__checkbox payment-form__checkbox_billing"
                isChecked={creditCardStore.isSameBillingAddress}
                isDisabled={isFormLoading}
                id="paymentFormBillingCheckbox"
                label="Billing address is the same as Purchaser address"
                onChange={creditCardStore.toggleIsSameBillingAddress}
              />
              {shouldShowBillingAddressForm && (
                <BillingAddress
                  className="payment-form__billing-address-data"
                  address={creditCardStore.billingAddress}
                  handleBillingAddressChange={creditCardStore.setBillingAddress}
                  isDisabled={isFormLoading}
                />
              )}
            </div>
          )}
        </div>
        <PaymentFormActions formStore={formStore} />
        {isShadow && (
          <div className="payment-form__shadow">
            {isPopupShown ? <PopupPaymentSuccess id={orderId} /> : <Loader />}
          </div>
        )}
      </div>
    );
  },
);

function getPaymentDetails(
  type: 'down' | 'full' | 'regular',
  { currentPayment, orderData, orderPayments }: PaymentPageStore,
): IDataListPair[] {
  const {
    agreementNumber,
    cemetery,
    payment,
    purchasers = [],
  } = orderData ?? {};

  switch (type) {
    case 'down':
      const firstRegularPaymentDate =
        orderData?.payment.firstRegularPaymentDate || orderPayments[0].dueDate;
      const lastRegularPaymentDate =
        orderPayments[orderPayments.length - 1].dueDate;

      return [
        {
          key: 'Agreement:',
          value: agreementNumber,
        },
        {
          key: 'Purchaser:',
          value: `${purchasers[0]?.name}`,
        },
        {
          key: 'Cemetery:',
          value: cemetery?.name,
        },
        {
          key: 'Instalment plan:',
          value: payment?.paymentFrequency,
        },
        {
          key: 'First regular payment date:',
          value: dayjs(firstRegularPaymentDate).format('MM/DD/YYYY'),
        },
        {
          key: 'Last regular payment date:',
          value: dayjs(lastRegularPaymentDate).format('MM/DD/YYYY'),
        },
        {
          key: 'Down payment:',
          value: orderData?.payment.downPayment,
        },
        {
          key: 'Regular payments:',
          value: orderData?.payment.monthlyPayment,
        },
        {
          key: 'Total:',
          value: payment?.total,
          isValueBold: true,
        },
      ];

    case 'regular':
      return [
        {
          key: 'Agreement:',
          value: agreementNumber,
        },
        {
          key: 'Purchaser:',
          value: `${purchasers[0]?.name}`,
        },
        {
          key: 'Cemetery:',
          value: cemetery?.name,
        },
        {
          key: 'Regular payments:',
          value: payment?.monthlyPayment,
        },
        {
          key: 'Total:',
          value: payment?.total,
          isValueBold: true,
        },
      ];

    case 'full':
      return [
        {
          key: 'Agreement:',
          value: agreementNumber,
        },
        {
          key: 'Purchaser:',
          value: `${purchasers[0]?.name}`,
        },
        {
          key: 'Cemetery:',
          value: cemetery?.name,
        },
        {
          key: 'Total:',
          value: formatAsUSDOptionalCents(currentPayment?.currentBalance ?? 0),
          isValueBold: true,
        },
      ];
  }
}

export default PaymentForm;
