import React from 'react';

import { Table } from 'src/components/common-components';

import { getFormattedServices } from 'src/utils';
import type { ServicesListProps } from './services-list.props';

import './services-list.scss';

const ServicesList = ({ services }: ServicesListProps) => (
  <Table
    className="table_services"
    columns={['Service Name', 'Price']}
    rows={getFormattedServices(services).map(({ name, price }) => [
      name,
      { className: 'table__cell_bold', value: price },
    ])}
  />
);

export default ServicesList;
