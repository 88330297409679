import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ordersStore from 'src/stores/orders-store';

import { AppRoute } from 'src/constants';

const useConditionalRedirect = () => {
  const history = useHistory();
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const { orders, signinOrders, isLoading, isOrdersTypeCalculated } =
    ordersStore;

  useEffect(() => {
    if (shouldRedirect && !isLoading && isOrdersTypeCalculated) {
      const isOneItem = orders.length === 1;
      // if user has one item and this item isn't signed
      if (isOneItem && signinOrders.length === 1) {
        history.push(`${AppRoute.SIGNING}/${signinOrders[0].id}`);
      } else if (isOneItem) {
        // if user has another type single item
        history.push(`${AppRoute.DASHBOARD}/${orders[0].id}`);
      }

      setShouldRedirect(false);
    }
  }, [
    orders,
    signinOrders,
    shouldRedirect,
    isLoading,
    isOrdersTypeCalculated,
    history,
  ]);
};

export default useConditionalRedirect;
