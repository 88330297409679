import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import Logo from 'src/components/common-components/logo';
import ButtonIcon from 'src/components/ui-components/button-icon';
import OrdersMenu from '../orders-menu';
import { ReactComponent as IconExit } from 'src/assets/icons/icon-exit.svg';

import globalStore from 'src/stores/global-store';
import ordersStore from 'src/stores/orders-store';
import processingStore from 'src/stores/processing-store';

import { LogoTextLocation } from 'src/constants';
import { useHeaderHeight } from 'src/hooks/use-header-height';

import './header.scss';

const Header = observer(() => {
  const ref = useRef<HTMLDivElement>(null);
  useHeaderHeight(ref);
  const { isModalShown, orders, toggleModal } = ordersStore;
  const isModalNeed =
    !processingStore.isProcessingPageShown &&
    !ordersStore.isOnlySigninOrder &&
    orders.length > 0;

  useEffect(() => {
    if (isModalShown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, [isModalShown]);

  return (
    <>
      <header className="header">
        <div
          ref={ref}
          className={classNames(
            'header__wrapper',
            !isModalNeed && 'header__wrapper_without-modal',
          )}
        >
          {isModalNeed && (
            <div className="header__modal">
              <button
                className={classNames(
                  'header__modal-toggle',
                  isModalShown && 'header__modal-toggle_opened',
                )}
                aria-label="Toggle Menu"
                onClick={toggleModal}
              >
                <span className="header__modal-toggle-particle" />
              </button>
              <span className="header__modal-title">Agreements List</span>
            </div>
          )}
          <Logo className="header__logo" textLocation={LogoTextLocation.SIDE} />

          <div className="header__menu">
            <span className="header__menu-user">{globalStore.user}</span>
            <ButtonIcon
              className="header__menu-logout"
              ariaLabel="Log out"
              icon={<IconExit />}
              onClick={globalStore.logOut}
              size="xs"
            />
          </div>
        </div>
        {isModalShown && <OrdersMenu className="header__orders-menu" />}
      </header>
      {isModalShown && <div className="header__overlay" />}
    </>
  );
});

export default Header;
