import { makeAutoObservable } from 'mobx';

import type PaymentFormStore from 'src/stores/payment-form/payment-form-store';
import processingStore from 'src/stores/processing-store';

import api from 'src/api';
import { Error } from 'src/constants';

class BankTransferStore {
  bankTransferAmount: number | null = null;
  plaidToken: string = '';
  paymentId: string;
  setFormError: PaymentFormStore['setFormError'];
  resetLoading: PaymentFormStore['resetLoading'];

  constructor(
    setFormError: PaymentFormStore['setFormError'],
    resetLoading: PaymentFormStore['resetLoading'],
    paymentId: string,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.paymentId = paymentId;
    this.setFormError = setFormError;
    this.resetLoading = resetLoading;
  }

  setBankTransferAmount(bankTransferAmount: number | null) {
    this.bankTransferAmount = bankTransferAmount;
  }

  setPlaidToken(token: string) {
    this.plaidToken = token;
  }

  handlePlaidExit() {
    this.resetLoading();
  }

  async handlePlaidSuccess(data: any) {
    if (!this.paymentId || !this.bankTransferAmount) {
      this.setFormError(Error.DEFAULT);
      this.resetLoading();
      return;
    }

    api
      .post(
        `/payments/${this.paymentId}/charge?amount=${this.bankTransferAmount}`,
        data,
      )
      .then(() => {
        if (this.paymentId) {
          processingStore.setSuccessPaymentProcessOn(this.paymentId);
        }
      })
      .catch((err) => {
        console.error(err);
        this.setFormError(Error.DEFAULT);
      });
  }

  async sendPlaidRequest(
    paymentId: string,
    onError: () => void,
    amount?: number,
  ) {
    if (amount) {
      this.setBankTransferAmount(amount);
    }

    try {
      const { data: paymentToken } = await api.get(
        `/payments/${paymentId}/linkToken`,
      );

      if (paymentToken) {
        this.setPlaidToken(paymentToken);
      }
    } catch (err: any) {
      console.error(err);
      onError();
    }
  }
}

export default BankTransferStore;
