import dayjs from 'dayjs';
import { makeAutoObservable, reaction } from 'mobx';

import globalStore from './global-store';

import api from 'src/api';
import {
  ApiStatus,
  Error,
  LoginStep,
  Regex,
  STORAGE_CODE_EXPIRED_KEY,
  STORAGE_PHONE_KEY,
} from 'src/constants';
import { getLocalStorageItem, setLocalStorageItem } from 'src/utils';

const TOTAL_TIMER_COUNT_SEC = 110;
const TIMER_INTERVAL = 1000; // 1s

function getTimerExpired() {
  const codeTimerExpired = getLocalStorageItem(STORAGE_CODE_EXPIRED_KEY);

  return codeTimerExpired ? parseInt(codeTimerExpired, 10) : 0;
}

function getIsCodeTimerRunning() {
  const codeTimeExpired = getTimerExpired();
  const nowTime = dayjs().unix();

  return codeTimeExpired && codeTimeExpired - nowTime > 0;
}

function clearLocalStorage() {
  setLocalStorageItem(STORAGE_CODE_EXPIRED_KEY, '');
  setLocalStorageItem(STORAGE_PHONE_KEY, '');
}

class AuthStore {
  phone: string = '';

  code: string = '';

  step: LoginStep = LoginStep.PHONE_ENTER;

  codeTimerId: number | null = null;

  codeTimerRemain: number = 0;

  isNewCodeRequested: boolean = false;

  isLoading: boolean = false;

  phoneRequestError: string = '';

  codeRequestError: string = '';

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.codeTimerRemain,
      (timer) => timer <= 0 && this.clearCodeTimer(),
    );

    this.init();

    this.getNewVerificationCode = this.getNewVerificationCode.bind(this);
    this.getVerificationCode = this.getVerificationCode.bind(this);
    this.sendCodeCheckRequest = this.sendCodeCheckRequest.bind(this);
    this.setCode = this.setCode.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.resetPhone = this.resetPhone.bind(this);
  }

  init() {
    const code = getLocalStorageItem(STORAGE_CODE_EXPIRED_KEY);
    const phone = getLocalStorageItem(STORAGE_PHONE_KEY);

    if (!getIsCodeTimerRunning()) {
      setLocalStorageItem(STORAGE_CODE_EXPIRED_KEY, '');
    } else {
      this.runCodeTimer();
    }

    if (code && phone) {
      this.setPhone(phone);
      this.setLoginStep(LoginStep.CODE_ENTER);
    }
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setCode(code: string) {
    this.code = code;
  }

  setLoginStep(step: LoginStep) {
    this.step = step;
  }

  setIsNewCodeRequested(isRequested: boolean) {
    this.isNewCodeRequested = isRequested;
  }

  setCodeTimeRemain(seconds: number) {
    this.codeTimerRemain = seconds;
  }

  setCodeTimerId(id: any) {
    this.codeTimerId = id;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPhoneRequestError(error: string) {
    this.phoneRequestError = error;
  }

  setCodeRequestError(error: string) {
    this.codeRequestError = error;
  }

  get isPhoneValid() {
    return Regex.PHONE.test(this.phone);
  }

  get isFormCodeValid() {
    return Regex.CODE.test(this.code);
  }

  get isNewCodeRequestDisabled() {
    return this.codeTimerRemain > 0;
  }

  get timerRemainingTime() {
    // eslint-disable-next-line no-bitwise
    const minutes = ~~(this.codeTimerRemain / 60);
    const seconds =
      minutes > 0
        ? this.codeTimerRemain % (60 * minutes)
        : this.codeTimerRemain;

    return `${minutes}\u00A0min\u00A0${
      seconds < 10 ? `0${seconds}` : seconds
    }\u00A0sec`;
  }

  async getVerificationCode() {
    const phone = getLocalStorageItem(STORAGE_PHONE_KEY);

    this.setIsNewCodeRequested(false);

    if (phone && phone === this.phone && getIsCodeTimerRunning()) {
      this.runCodeTimer();
      this.setLoginStep(LoginStep.CODE_ENTER);

      return;
    }

    if (phone !== this.phone) {
      setLocalStorageItem(STORAGE_PHONE_KEY, this.phone);
    }

    await this.sendCodeRequest();
  }

  async getNewVerificationCode() {
    this.setCode('');
    this.setIsNewCodeRequested(true);

    const newCodeTimeExpired = dayjs()
      .add(TOTAL_TIMER_COUNT_SEC, 's')
      .unix()
      .toString();

    setLocalStorageItem(STORAGE_CODE_EXPIRED_KEY, newCodeTimeExpired);
    setLocalStorageItem(STORAGE_PHONE_KEY, this.phone);

    await this.sendCodeRequest();
  }

  async sendCodeRequest() {
    this.setIsLoading(true);

    if (this.phoneRequestError) {
      this.setPhoneRequestError('');
    }

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
      };

      const result = await api.post(
        '/personal_area/sendVerificationCode',
        options,
      );

      if (result.status === ApiStatus.SUCCESS) {
        const codeTimeExpired = dayjs()
          .add(TOTAL_TIMER_COUNT_SEC, 's')
          .unix()
          .toString();

        setLocalStorageItem(STORAGE_CODE_EXPIRED_KEY, codeTimeExpired);

        this.runCodeTimer();
        this.setLoginStep(LoginStep.CODE_ENTER);
      }
    } catch (err: any) {
      console.error('ERROR: ', err);
      if (err?.response?.data?.userMessage) {
        this.setPhoneRequestError(err.response.data.userMessage);
      } else if (err?.response?.data?.message) {
        this.setPhoneRequestError(err.response.data.message);
      } else {
        this.setPhoneRequestError(Error.DEFAULT);
      }
    }

    this.setIsLoading(false);
  }

  async sendCodeCheckRequest() {
    if (!this.isFormCodeValid && this.codeRequestError) {
      this.setLoginStep(LoginStep.CODE_ENTER);
      this.setCodeRequestError('');
      return;
    }

    if (!this.isFormCodeValid || !this.isLoginStep(LoginStep.CODE_ENTER)) {
      return;
    }

    this.setIsLoading(true);

    try {
      const options = {
        phone: this.phone.replace(/\D/g, ''),
        code: this.code,
      };

      const result = await api.post('/personal_area/loginByPhone', options);

      if (result.status === ApiStatus.SUCCESS) {
        const token = result.headers?.authorization || '';
        globalStore.setToken(token);
        clearLocalStorage();
      }
    } catch (err: any) {
      console.error('ERROR: ', err);
      if (err?.response?.status === ApiStatus.FORBIDDEN) {
        this.setCodeRequestError(
          err?.response?.data?.message || Error.CODE_ENTER,
        );
      } else if (err?.response?.status === ApiStatus.BAD_REQUEST) {
        this.setCodeRequestError(Error.CODE_ENTER);
      } else {
        this.setCodeRequestError(Error.DEFAULT);
      }
      this.setLoginStep(LoginStep.CODE_ENTER);
    }

    this.setIsLoading(false);
  }

  clearCodeTimer() {
    this.setCodeTimeRemain(0);
    this.setIsNewCodeRequested(false);

    if (this.codeTimerId) {
      clearInterval(this.codeTimerId);
    }

    setLocalStorageItem(STORAGE_CODE_EXPIRED_KEY, '');
  }

  runCodeTimer() {
    this.updateCodeTimeRemain();

    const timerId = setInterval(
      () => this.updateCodeTimeRemain(),
      TIMER_INTERVAL,
    );

    this.setCodeTimerId(timerId);
  }

  updateCodeTimeRemain() {
    const nowTime = dayjs().unix();
    const timeDiff = getTimerExpired() - nowTime;

    if (timeDiff > 0) {
      this.setCodeTimeRemain(timeDiff);
    } else {
      this.clearCodeTimer();
    }
  }

  isLoginStep(step: LoginStep) {
    return step === this.step;
  }

  resetPhone() {
    this.setLoginStep(LoginStep.PHONE_ENTER);
    this.setPhone('');
    this.setCode('');
    this.setIsNewCodeRequested(false);
    this.setCodeRequestError('');
  }
}

export default AuthStore;
