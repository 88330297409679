import { makeAutoObservable, reaction } from 'mobx';

import globalStore from './global-store';

import {
  transformOrderFromApi,
  transformOrdersFromApi,
} from 'src/adapters/orders';
import { transformPaymentsFromApi } from 'src/adapters/payments';
import api from 'src/api';
import {
  ApiStatus,
  AppRoute,
  OrderStatus,
  PaymentStatus,
  PaymentStatusApi,
  PaymentType,
  PaymentTypeApi,
} from 'src/constants';
import { formatUSDMoneyToCents } from 'src/utils';
import type {
  IApiSinglePayment,
  IListOrder,
  IOrder,
  IPayment,
  ISinglePayment,
} from 'src/interfaces';

class OrdersStore {
  isLoading: boolean = true;
  isAllPaymentsLoading: boolean = false;
  isModalShown: boolean = false;
  orders: IListOrder[] = [];
  allPayments: IPayment[] = [];
  selectedOrderId: string = '';
  selectedOrder: IOrder | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.orders,
      (data) => {
        this.updateSelectedOrder(data);
        this.loadAllPayments(data);
      },
    );

    reaction(
      () => this.selectedOrderId,
      (data) => this.loadSelectedOrder(data),
    );
  }

  init(id: string) {
    if (id) {
      this.setSelectedOrderId(id);
    }
    this.loadOrders();
  }

  get activeOrders() {
    if (this.isContentLoading) {
      return [];
    }

    return this.orders.filter(
      (order) =>
        (order?.status === OrderStatus.PARTIALLY_PAID ||
          order?.status === OrderStatus.PENDING_PAYMENT) &&
        this.getOrderDueDate(order.id),
    );
  }

  get completedOrders() {
    if (this.isContentLoading) {
      return [];
    }

    return this.orders.filter(
      (order) =>
        order?.status === OrderStatus.PAID ||
        ((order?.status === OrderStatus.PARTIALLY_PAID ||
          order?.status === OrderStatus.PENDING_PAYMENT) &&
          !this.getOrderDueDate(order.id)),
    );
  }

  get isContentLoading() {
    return this.isLoading || this.isAllPaymentsLoading;
  }

  get isOnlySigninOrder() {
    return (
      this.orders.length === 1 &&
      this.orders[0]?.status === OrderStatus.PENDING_SIGNATURE
    );
  }

  get signinOrders() {
    if (this.isContentLoading) {
      return [];
    }

    return this.orders.filter(
      (order) => order?.status === OrderStatus.PENDING_SIGNATURE,
    );
  }

  get notSignedOrders() {
    if (this.isContentLoading) {
      return [];
    }

    return this.orders.filter(
      (order) => order?.status !== OrderStatus.PENDING_SIGNATURE,
    );
  }

  get isOrdersTypeCalculated() {
    return (
      !!this.orders.length &&
      this.notSignedOrders.length + this.signinOrders.length ===
        this.orders.length
    );
  }

  get paymentsBySelectedOrderId() {
    return this.allPayments.find(
      (item) => item.orderId === this.selectedOrder?.id,
    );
  }

  get selectedOrderRemainingBalance() {
    return this.paymentsBySelectedOrderId?.currentBalance ?? 0;
  }

  get selectedOrderPaymentsList() {
    return this.paymentsBySelectedOrderId?.paymentsList ?? [];
  }

  get selectedOrderNextPayment() {
    return this.paymentsBySelectedOrderId?.nextPayment || 0;
  }

  get selectedOrderDueDate() {
    return this.paymentsBySelectedOrderId?.dueDate;
  }

  get isOrderPaymentsLoaded() {
    return !!(
      this.selectedOrder &&
      !this.isContentLoading &&
      this.paymentsBySelectedOrderId?.paymentsList
    );
  }

  /**
   * Check if order payments are paid but has any pending status
   * @param  {string} id {order id}
   * @return {boolean} {is all statuses paid and at least one pending}
   */
  checkLastPendingPayment(id: string) {
    const orderPayments = this.allPayments.find(
      (item) => item.orderId === id,
    )?.paymentsList;
    const noUnpaidPayments = orderPayments?.every(
      (it) => it.status !== PaymentStatus[PaymentStatusApi.UNPAID],
    );
    const anyPendingStatus = orderPayments?.some(
      (it) => it.status === PaymentStatus[PaymentStatusApi.PENDING],
    );
    return noUnpaidPayments && anyPendingStatus;
  }

  getOrderDueDate(orderId: string) {
    return this.allPayments.find((item) => item.orderId === orderId)?.dueDate;
  }

  getOrderNextPayment(id: string) {
    return (
      this.allPayments.find((item) => item.orderId === id)?.nextPayment || 0
    );
  }

  getIsNextPaymentDisabled(id: string) {
    const orderPayments = this.getOrderPaymentList(id);

    return !!orderPayments.find(
      (item) => item.status === PaymentStatus[PaymentStatusApi.PENDING],
    );
  }

  getOrderRemainingBalance(orderId: string) {
    return (
      this.allPayments.find((item) => item.orderId === orderId)
        ?.currentBalance ?? 0
    );
  }

  getOrderPaidAmount(orderId: string) {
    return (
      this.allPayments.find((item) => item.orderId === orderId)?.paidAmount ?? 0
    );
  }

  getOrderPaymentList(orderId: string) {
    return (
      this.allPayments.find((item) => item.orderId === orderId)?.paymentsList ??
      []
    );
  }

  async loadOrders() {
    if (!globalStore.token) {
      return;
    }

    this.setIsLoading(true);
    try {
      const result = await api.get('/purchases');

      if (result.status === ApiStatus.SUCCESS) {
        this.setOrders(transformOrdersFromApi(result.data));
      }
    } catch (err) {
      console.error('err', err);
      this.setOrders([]);
    } finally {
      this.setIsLoading(false);
    }
  }

  async loadAllPayments(orders: IListOrder[]) {
    if (!globalStore.token) {
      return;
    }

    this.setIsAllPaymentsLoading(true);

    const promises = orders.map((item) => {
      const params = {
        purchaseId: item.id,
        sort: JSON.stringify({ due_date: 'asc' }),
      };

      return api.get<IApiSinglePayment[]>(`/payments`, {
        params,
      });
    });

    let newArray = [...this.allPayments];

    const results = await Promise.allSettled(promises);

    results.forEach((result) => {
      let paymentsList: ISinglePayment[];
      let orderId: string;
      let order: IListOrder | undefined;

      if (result.status === 'fulfilled') {
        orderId = result.value.config.params.purchaseId;
        order = orders.find(({ id }) => id === orderId);
        paymentsList = result.value.data
          ? transformPaymentsFromApi(result.value.data, order?.totalAmount || 0)
          : [];
      } else if (result.status === 'rejected') {
        paymentsList = [];
        orderId = result.reason.config.params.purchaseId;
        order = orders.find(({ id }) => id === orderId);
      } else {
        return;
      }

      const totalPurchaseAmount = order?.totalAmount;
      const existingPaymentIdx = this.allPayments.findIndex(
        (it: IPayment) => it.orderId === orderId,
      );
      const closestUnpaidItem = paymentsList.find(
        (it: ISinglePayment) =>
          it.status === PaymentStatus[PaymentStatusApi.UNPAID],
      );
      const lastPaymentItem = paymentsList[paymentsList.length - 1];
      const currentUnpaidBalance =
        result.status === 'rejected' && !!order?.payment?.total // temporal payments fetch error for scattering orders
          ? formatUSDMoneyToCents(order.payment.total) || 0
          : lastPaymentItem?.currentBalance ?? totalPurchaseAmount;
      const nextPayment = closestUnpaidItem?.amount || 0;
      const paidAmount = totalPurchaseAmount
        ? totalPurchaseAmount - currentUnpaidBalance
        : 0;

      if (existingPaymentIdx === -1) {
        newArray.push({
          ...(order?.payment.type ===
            PaymentType[PaymentTypeApi.INSTALLMENT] && {
            regularPayment: closestUnpaidItem?.amount || 0,
          }),
          nextPayment,
          orderId,
          currentBalance: currentUnpaidBalance,
          paidAmount,
          dueDate: closestUnpaidItem?.dueDate,
          paymentsList,
        });
      } else {
        const existingPayment = { ...newArray[existingPaymentIdx] };

        existingPayment.nextPayment = nextPayment;
        existingPayment.currentBalance = currentUnpaidBalance;
        existingPayment.paidAmount = paidAmount;
        existingPayment.dueDate = closestUnpaidItem?.dueDate;
        existingPayment.paymentsList = paymentsList;

        newArray = [
          ...newArray.slice(0, existingPaymentIdx),
          existingPayment,
          ...newArray.slice(existingPaymentIdx + 1),
        ];
      }
    });

    this.setAllPayments(newArray);
    this.setIsAllPaymentsLoading(false);
  }

  async loadSelectedOrder(id: string) {
    if (!globalStore.token || !id) {
      return;
    }

    const handleFailDataFetch = () => {
      this.setSelectedOrder(null);
      this.setSelectedOrderId('');
      window.location.href = AppRoute.DASHBOARD;
    };

    this.setIsLoading(true);
    try {
      const result = await api.get(`/purchases/${id}`);
      if (result.data) {
        this.setSelectedOrder(transformOrderFromApi(result.data));
      } else {
        handleFailDataFetch();
      }
    } catch (err) {
      console.error('err', err);
      handleFailDataFetch();
    } finally {
      this.setIsLoading(false);
    }
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsAllPaymentsLoading(isLoading: boolean) {
    this.isAllPaymentsLoading = isLoading;
  }

  setIsModalShown(isModalShown: boolean) {
    this.isModalShown = isModalShown;
  }

  setOrders(orders: IListOrder[]) {
    this.orders = orders;
  }

  setAllPayments(data: IPayment[]) {
    this.allPayments = data;
  }

  setSelectedOrder(order: IOrder | null) {
    this.selectedOrder = order;
  }

  setSelectedOrderId(id: string) {
    if (this.isModalShown) {
      this.setIsModalShown(false);
    }
    this.selectedOrderId = id;
  }

  toggleModal() {
    this.isModalShown = !this.isModalShown;
  }

  updateSelectedOrder(orders: IOrder[]) {
    const existingOrder = orders.find(
      (order) => order.id === this.selectedOrderId,
    );
    if (!existingOrder || !this.selectedOrderId) {
      const id = orders?.length ? orders[0]?.id : '';
      this.setSelectedOrderId(id);
    } else {
      this.setSelectedOrderId(existingOrder.id);
    }
  }

  clearStore() {
    this.setIsLoading(true);
    this.setIsModalShown(false);
    this.setOrders([]);
    this.setAllPayments([]);
    this.setSelectedOrderId('');
    this.setSelectedOrder(null);
  }
}

export default new OrdersStore();
