import React from 'react';
import classNames from 'classnames';

import type { RadioboxProps } from './radiobox.props';

import './radiobox.scss';

function Radiobox({
  id,
  label,
  name,
  isChecked,
  isDisabled,
  onChange,
}: RadioboxProps) {
  const value = label || id;

  return (
    <div className={classNames('radiobox', isDisabled && 'radiobox_disabled')}>
      <input
        checked={isChecked}
        className="radiobox__input"
        id={id}
        name={name}
        disabled={isDisabled}
        onChange={() => onChange(value || '')}
        type="radio"
      />
      <label htmlFor={id} className="radiobox__label">
        <span className="radiobox__icon" />
        {label}
      </label>
    </div>
  );
}

export default Radiobox;
