import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  CustomScrollbar,
  Footer,
  Loader,
} from 'src/components/common-components';
import DetailsPageContent from './components/details-page-content';
import NextPayment from './components/next-payment';
import OrderInfo from './components/order-info';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderStatus } from 'src/constants';

import './details-page.scss';

const DetailsPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    selectedOrder,
    selectedOrderId,
    isOrderPaymentsLoaded,
    getOrderDueDate,
    getIsNextPaymentDisabled,
    selectedOrderNextPayment,
  } = ordersStore;

  useEffect(() => {
    if (id !== selectedOrderId && !selectedOrder) {
      ordersStore.init(id);
    }
  }, [id, selectedOrder, selectedOrderId]);

  if (!selectedOrder || !isOrderPaymentsLoaded) {
    return <Loader className="loader_position_overlay" noDebounce />;
  }

  if (selectedOrder.status === OrderStatus.PENDING_SIGNATURE) {
    return <Redirect to={`${AppRoute.SIGNING}/${id}`} />;
  }

  return (
    <CustomScrollbar width="100%" height="100%">
      <div className="details-page">
        <div className="details-page__wrapper">
          <div className="details-page__lot">
            <OrderInfo />
            {!!getOrderDueDate(selectedOrder.id) && (
              <NextPayment
                id={selectedOrder.id}
                isNextPaymentDisabled={getIsNextPaymentDisabled(
                  selectedOrder.id,
                )}
                total={selectedOrderNextPayment}
              />
            )}
          </div>
          <DetailsPageContent />
        </div>
        <Footer />
      </div>
    </CustomScrollbar>
  );
});

export default DetailsPage;
