import React from 'react';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/icon-arrow.svg';

import type { PaymentDetailsProps } from './payment-details.props';

import './payment-details.scss';

const PaymentDetails = ({ children }: PaymentDetailsProps) => (
  <>
    <input
      className="payment-details__checkbox"
      id="payment-details-checkbox"
      type="checkbox"
    />
    <label
      className="payment-details__label"
      htmlFor="payment-details-checkbox"
    >
      <span className="payment-details__title">Details</span>
      <span className="payment-details__arrow-wrapper">
        <ArrowIcon className="payment-details__arrow" />
      </span>
    </label>
    <div className="payment-details__content">{children}</div>
  </>
);

export default PaymentDetails;
