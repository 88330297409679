import React from 'react';

import {
  AFSLogo,
  ExternalLink,
  Footer,
  FooterLinks,
} from 'src/components/common-components';
import Logo from 'src/components/common-components/logo';
import LoginForm from './components/login-form';

import { LogoTextLocation } from 'src/constants';

import './login-page.scss';

const LoginPage = () => (
  <div className="login-page">
    <div className="login-page__wrapper">
      <div className="login-page__background-wrapper">
        <Logo
          textLocation={LogoTextLocation.BOTTOM}
          className="login-page__logo-mobile"
        />

        <picture>
          <source
            srcSet="/main-bg/family-portal/mobile.jpg"
            media="(max-width: 640px)"
          />
          <source
            srcSet="/main-bg/family-portal/tablet.jpg"
            media="(max-width: 1440px)"
          />
          <source
            srcSet="/main-bg/family-portal/desktop.jpg"
            media="(max-width: 1920px)"
          />
          <source
            srcSet="/main-bg/family-portal/fullhd.jpg"
            media="(max-width: 2560px)"
          />
          <img
            className="login-page__background-image"
            src="/main-bg/family-portal/2k.jpg"
            alt="Cemetery view"
          />
        </picture>
        <ExternalLink
          className="login-page__footer-logo"
          href="https://allfuneral.com/"
          title="All Funeral Services"
        >
          <AFSLogo className="login-page__footer-logo-afs" page="login" />
        </ExternalLink>
      </div>
      <div className="login-page__form-wrapper">
        <Logo
          className="login-page__logo"
          textLocation={LogoTextLocation.BOTTOM}
          isColored
        />
        <LoginForm className="login-page__form" />

        <FooterLinks className="login-page__links" page="login" />
      </div>
    </div>
    <Footer className="login-page__footer" />
  </div>
);

export default LoginPage;
