import { OrderStatus } from 'src/constants';
import type { IOrder } from 'src/interfaces';

export function checkAgreementShow(order: IOrder) {
  return (
    order.status !== OrderStatus.PENDING_SIGNATURE &&
    order.needSignAgreement &&
    !order.onlyMceAgreement &&
    !!order.purchasers[0]?.email
  );
}
