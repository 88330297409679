import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import type { PlaidWrapperProps } from './plaid-wrapper.props';

const PlaidWrapper = ({
  plaidToken,
  handlePlaidSuccess,
  handlePlaidError,
  handlePlaidExit,
}: PlaidWrapperProps) => {
  const onSuccess = (public_token: string, metadata: any) => {
    handlePlaidSuccess({ public_token, account_id: metadata?.accounts[0]?.id });
  };

  const onExit = (error: any) => {
    handlePlaidError(error?.display_message);
    handlePlaidExit();
  };

  const config = {
    token: plaidToken,
    onSuccess,
    onExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  useEffect(() => {
    // @ts-ignore
    handlePlaidError(error?.display_message);
  }, [error, handlePlaidError]);

  return null;
};

export default PlaidWrapper;
