import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import type { ButtonLinkProps } from './button-link.props';

import './button-link.scss';

const ButtonLink = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonLinkProps
>(
  (
    {
      className,
      isDashed,
      isDisabled,
      isSpaced,
      isUnderline,
      isUppercase,
      label,
      onClick,
      color = 'default',
      tag = 'button',
      target,
      to = '',
      type = 'button',
      size = 'common',
      weight = 'regular',
      ...props
    },
    ref,
  ) => {
    const buttonClasses = classNames(
      'button-link',
      size === 'accent' && 'button-link_accent',
      weight === 'bold' && 'button-link_weight_bold',
      color === 'accent' && 'button-link_color_accent',
      color === 'secondary' && 'button-link_color_secondary',
      isDashed && 'button-link_dashed',
      isDisabled && 'button-link_disabled',
      isSpaced && 'button-link_spaced',
      isUnderline && 'button-link_underline',
      isUppercase && 'button-link_uppercase',
      tag === 'button' || 'button-link_link-tag-reset',
      className,
    );

    const renderComponent = () => {
      switch (tag) {
        case 'button':
          return (
            <button
              className={buttonClasses}
              onClick={onClick}
              type={type}
              disabled={isDisabled}
              ref={ref as React.ForwardedRef<HTMLButtonElement>}
              {...props}
            >
              {label}
            </button>
          );
        case 'link':
          return (
            <Link
              className={buttonClasses}
              onClick={onClick}
              to={to}
              target={target || '_self'}
              ref={ref as React.ForwardedRef<HTMLAnchorElement>}
              {...props}
            >
              {label}
            </Link>
          );
        case 'a':
          return (
            <a
              className={buttonClasses}
              href={to}
              onClick={onClick}
              rel="noopener noreferrer"
              target={target || '_blank'}
              ref={ref as React.ForwardedRef<HTMLAnchorElement>}
              {...props}
            >
              {label}
            </a>
          );
        default:
          return null;
      }
    };
    return <>{renderComponent()}</>;
  },
);

export default ButtonLink;
