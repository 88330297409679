export enum AppRoute {
  ROOT = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  PAYMENT = '/payment',
  DASHBOARD = '/dashboard',
  SIGNING = '/signing',
  TERMS = '/terms',
  POLICY = '/policy',
  RESULTS = '/results',
}
