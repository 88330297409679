import React, { useEffect } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import classNames from 'classnames';

import type { CustomScrollbarProps } from './custom-scrollbar.props';

import './custom-scrollbar.scss';

const DESKTOP_WIDTH = 1280;
const LISTENER_DEBOUNCE_INTERVAL = 200;

export const ScrollbarContext = React.createContext<
  null | ((val: boolean) => void)
>(null);

function CustomScrollbar({
  className,
  height,
  width,
  children,
}: CustomScrollbarProps) {
  const [isMobile, setIsMobile] = React.useState(
    window.innerWidth < DESKTOP_WIDTH,
  );
  const [isMounted, setIsMounted] = React.useState(false);
  const [isNoScroll, setIsNoScroll] = React.useState(false);

  useEffect(() => {
    const onResize = () => {
      if (isMounted) {
        setTimeout(() => {
          if (window.innerWidth < DESKTOP_WIDTH && !isMobile) {
            setIsMobile(true);
          }

          if (window.innerWidth >= DESKTOP_WIDTH && isMobile) {
            setIsMobile(false);
          }
        }, LISTENER_DEBOUNCE_INTERVAL);
      }
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isMobile, isMounted]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  if (isMobile) {
    return <>{children}</>;
  }

  return (
    <ScrollbarContext.Provider value={setIsNoScroll}>
      <Scrollbar
        className={classNames('custom-scrollbar', className)}
        style={{ width, height }}
        noScroll={isNoScroll}
      >
        {children}
      </Scrollbar>
    </ScrollbarContext.Provider>
  );
}

export default CustomScrollbar;
