import React from 'react';
import classNames from 'classnames';

import { Pagination } from 'src/components/common-components';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/icon-arrow.svg';

import { InstalmentStatus } from 'src/constants';
import usePagination from 'src/hooks/use-pagination';
import useToggle from 'src/hooks/use-toggle';
import {
  formatAsUSDOptionalCents,
  formatDate,
  getPaymentStatus,
} from 'src/utils';
import type { OrderAccordionProps } from './order-accordion.props';

import './order-accordion.scss';

const OrderAccordion = ({
  className,
  list,
  currentDate,
}: OrderAccordionProps) => {
  const { isActive: isOpened, toggle: toggleAccordion } = useToggle(false);
  const {
    decrementPage,
    endListCount,
    incrementPage,
    isShowPagination,
    list: tableList,
    pageCount,
    setFirstPage,
    setLastPage,
    skipDataCount,
    startListCount,
    totalDataCount,
  } = usePagination(list);

  return (
    <div
      className={classNames(
        'order-accordion',
        isOpened && 'order-accordion_opened',
        className,
      )}
    >
      <button className="order-accordion__button" onClick={toggleAccordion}>
        <span className="order-accordion__button-text">Instalment Plan</span>
        <ArrowIcon className="order-accordion__button-icon" />
      </button>
      {isOpened && (
        <div className="order-accordion__dropdown">
          <ul className="order-accordion__list">
            {tableList.map((item, index) => {
              const status = getPaymentStatus(
                item.status,
                item.dueDate,
                currentDate,
                pageCount,
                index,
              );
              const formattedDate = formatDate(item.dueDate);

              return (
                <li
                  key={item.id}
                  className={classNames(
                    'order-accordion__list-item',
                    status === InstalmentStatus.MISSED &&
                      'order-accordion__list-item_missed',
                  )}
                >
                  <span className="order-accordion__list-item-text">
                    {formattedDate}
                  </span>
                  <span className="order-accordion__list-item-text">
                    {formatAsUSDOptionalCents(item?.paidAmount || item.amount)}
                  </span>
                  <span
                    className={classNames(
                      'order-accordion__list-item-text',
                      'order-accordion__list-item-text_status',
                      `order-accordion__list-item-text_${status.toLowerCase()}`,
                    )}
                  >
                    {status}
                  </span>
                </li>
              );
            })}
          </ul>
          {isShowPagination && (
            <Pagination
              className="order-accordion__pagination"
              isFirstPage={pageCount === 1}
              isLastPage={tableList.length + skipDataCount >= totalDataCount}
              onFirstPageButtonClick={setFirstPage}
              onLastPageButtonClick={setLastPage}
              onPreviousPageButtonClick={decrementPage}
              onNextPageButtonClick={incrementPage}
              startListCount={startListCount}
              endListCount={endListCount}
              totalDataCount={totalDataCount}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrderAccordion;
