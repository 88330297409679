import React from 'react';
import classNames from 'classnames';

import { ButtonLink } from 'src/components/ui-components';

import type { TabsProps } from './tabs.props';

import './tabs.scss';

const Tabs = ({
  activeTab,
  className,
  isTabsDisabled,
  isTabsOversize,
  tabs,
}: TabsProps) => (
  <div className={classNames('tabs', className)}>
    <div className="tabs__wrapper">
      {tabs.map(({ isDisabled, label, name = label, handleClick }) => (
        <ButtonLink
          key={name}
          className={classNames(
            'tabs__item',
            activeTab === name && 'tabs__item_active',
            isTabsDisabled && 'tabs__item_disabled',
            isTabsOversize && 'tabs__item_oversize',
          )}
          isDisabled={isDisabled || isTabsDisabled}
          isSpaced
          label={label}
          onClick={handleClick}
        />
      ))}
    </div>
  </div>
);

export default Tabs;
