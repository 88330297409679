export const PaymentStatusApi = {
  PAID: 'paid',
  PENDING: 'pending_payment',
  UNPAID: 'unpaid',
};

export const PaymentStatus = {
  [PaymentStatusApi.PAID]: 'Paid',
  [PaymentStatusApi.PENDING]: 'Pending payment',
  [PaymentStatusApi.UNPAID]: 'Waiting',
};

export enum InstalmentStatus {
  PENDING = 'Pending',
  CURRENT = 'Current',
  MISSED = 'Missed',
  WAITING = 'Waiting',
}
