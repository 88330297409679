import type { IService } from 'src/interfaces';

/**
 * Transforms cents amount in dollars US format
 * @param  {number | string} value {cents}
 * @return {string} {in dollars with $}
 */
export function formatAsUSDollars(
  value: number | string,
) {
  return typeof value === 'number'
    ? (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
    : '';
}
/**
 * Transforms cents amount in dollars US format with optional cents
 * @param  {number | string} value {cents}
 * @return {string} {in dollars with $ and optional cents}
 */
export function formatAsUSDOptionalCents(
  value: number | string,
) {
  return typeof value === 'number'
    ? (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      })
    : '';
}

/**
 * Calculates sum of services
 * @param  {array} services {array of object's keys: count, service(obj) }
 * @return {number} {total price amount of all list services}
 */
export function sumServicesAmount(services: IService[]) {
  return services.reduce(
    (sum, item) => sum + item.count * item.service.price,
    0,
  );
}

/**
 * Calculates sum of services excluding prepaid ones
 * @param  {array} services {array of object's keys: count, service(obj) }
 * @return {number} {total price amount of all list services}
 */
export function sumServicesAmountExcludingPrepaid(services: IService[]) {
  return services.reduce(
    (sum, item) =>
      sum +
      item.count * item.service.price -
      (item.prepaid_count ?? 0) * item.service.price,
    0,
  );
}

/**
 * Transforms dollars US format to cents
 * @param  {string} value {in dollars with $ and ,}
 * @return {number} {cents}
 */
export function formatUSDMoneyToCents(value: string) {
  return typeof value === 'string'
    ? Math.round(Number(value.replace(/\$|,/g, '')) * 100)
    : '';
}
