export const InstallmentTypeApi = {
  MONTHLY: 'monthly',
  TWO_MONTHS: 'two_months',
  QUARTERLY: 'quarterly',
  ANNUAL: 'annual',
};

export const InstallmentType = {
  [InstallmentTypeApi.MONTHLY]: 'Monthly payments',
  [InstallmentTypeApi.TWO_MONTHS]: 'Two months payments',
  [InstallmentTypeApi.QUARTERLY]: 'Quarterly payments',
  [InstallmentTypeApi.ANNUAL]: 'Annual payments',
};
