import React from 'react';
import classNames from 'classnames';

import { Input } from 'src/components/ui-components';

import { LabelTheme } from 'src/constants';
import type { BillingAddressProps } from './billing-address.props';

import './billing-address.scss';

const BillingAddress = ({
  className,
  address,
  handleBillingAddressChange,
  isDisabled,
}: BillingAddressProps) => {
  const { building, street, room, city, state, zip } = address;

  const handleInputChange = (addressPart: string, value: string) => {
    const newAddress = { ...address, [addressPart]: value };
    handleBillingAddressChange(newAddress);
  };

  return (
    <div className={classNames('billing-address', className)}>
      <Input
        id="billingAddressBuilding"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="Building"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('building', val)}
        value={building || ''}
      />
      <Input
        id="billingAddressStreet"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="Street"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('street', val)}
        value={street || ''}
      />
      <Input
        id="billingAddressApt"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="Apt/Room/Office"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('room', val)}
        value={room || ''}
      />
      <Input
        id="billingAddressCity"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="City"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('city', val)}
        value={city || ''}
      />
      <Input
        id="billingAddressState"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="State"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('state', val)}
        value={state || ''}
      />
      <Input
        id="billingAddressZip"
        className="billing-address__input"
        isDisabled={isDisabled}
        label="Zip"
        labelColor={LabelTheme.LIGHT}
        onChange={(val) => handleInputChange('zip', val)}
        value={zip || ''}
      />
    </div>
  );
};

export default BillingAddress;
