import React from 'react';

import { Button } from 'src/components/ui-components';

import { AppRoute } from 'src/constants';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { NextPaymentProps } from './next-payment.props';

import './next-payment.scss';

const NextPayment = ({
  id,
  isNextPaymentDisabled,
  total,
}: NextPaymentProps) => (
  <div className="next-payment">
    <div className="next-payment__data">
      <div className="next-payment__data-label">Next Payment:</div>
      <div className="next-payment__data-value">
        {formatAsUSDOptionalCents(total)}
      </div>
    </div>
    <Button
      className="next-payment__button"
      href={`${AppRoute.PAYMENT}/${id}`}
      isDisabled={isNextPaymentDisabled}
      tag="link"
      label="Pay now"
      theme="filled"
      size="normal"
    />
  </div>
);

export default NextPayment;
