import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { CustomScrollbar, Footer } from 'src/components/common-components';
import MainPageContent from './components/main-page-content';

import ordersStore from 'src/stores/orders-store';
import processingStore from 'src/stores/processing-store';

import { ProcessingStatus } from 'src/constants';

import './main-page.scss';

const MainPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    ordersStore.init(id);
  }, [id]);

  if (
    processingStore.status === ProcessingStatus.OFF &&
    processingStore.redirectPath
  ) {
    const path = processingStore.redirectPath;
    processingStore.setRedirectPath('');
    history.push(path);
  }

  return (
    <CustomScrollbar width="100%" height="100%">
      <div className="main-page">
        <div className="main-page__wrapper">
          <MainPageContent />
        </div>
        <Footer />
      </div>
    </CustomScrollbar>
  );
});

export default MainPage;
