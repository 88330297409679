import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import LogoText from './components/logo-text';

import {
  AppRoute,
  IS_LOGO_TEXT_SEPARATED,
  LogoTextLocation,
} from 'src/constants';
import { getCemeteryLogoProps } from 'src/utils/get-cemetery-logo-props';
import type { LogoProps } from './logo.props';

import './logo.scss';

const Logo = ({ className, textLocation, isColored }: LogoProps) => {
  const { logoClasses, logoSrc, logoWidth, logoHeight } = getCemeteryLogoProps(
    textLocation,
    IS_LOGO_TEXT_SEPARATED,
    isColored,
  );

  return textLocation === LogoTextLocation.BOTTOM ? (
    <div
      className={classNames(
        `logo logo_${textLocation}`,
        className,
        isColored && 'logo_colored',
      )}
    >
      <img
        className={logoClasses}
        src={logoSrc}
        alt="Cemetery logo"
        width={logoWidth}
        height={logoHeight}
      />
      {IS_LOGO_TEXT_SEPARATED && (
        <LogoText
          location={textLocation}
          title={window.APP_CEMETERY_LOGO_TITLE}
          subtitle={window.APP_CEMETERY_LOGO_SUBTITLE}
        />
      )}
    </div>
  ) : (
    <>
      <Link
        className={classNames(
          `logo logo_medium logo_${textLocation}`,
          className,
        )}
        to={AppRoute.ROOT}
      >
        <img
          className={logoClasses}
          src={logoSrc}
          alt="Cemetery logo"
          width={logoWidth}
          height={logoHeight}
        />
        {IS_LOGO_TEXT_SEPARATED && (
          <LogoText
            location={textLocation}
            title={window.APP_CEMETERY_LOGO_TITLE}
            subtitle={window.APP_CEMETERY_LOGO_SUBTITLE}
          />
        )}
      </Link>

      <Link
        className={classNames(`logo logo_small-${textLocation}`, className)}
        to={AppRoute.ROOT}
      >
        <img
          alt="Cemetery logo"
          className="logo__img"
          height={32}
          src="/logos/logo-small-colored.svg"
          width={32}
        />
      </Link>
    </>
  );
};

export default Logo;
