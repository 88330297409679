import { PaymentStatus } from 'src/constants';
import type { IApiSinglePayment, ISinglePayment } from 'src/interfaces';

export const transformPaymentShapeFromApi = (
  apiPaymentData: IApiSinglePayment,
  currentBalance: number,
): ISinglePayment => ({
  id: apiPaymentData.id,
  orderId: apiPaymentData.purchase?.id,
  amount: apiPaymentData.amount,
  currentBalance,
  dueDate: apiPaymentData.due_date,
  method: apiPaymentData.method ?? '',
  paidAmount: apiPaymentData.paid_amount ?? null,
  paymentCheck: apiPaymentData.reference_number ?? '',
  paymentDate: apiPaymentData.payment_date,
  paymentType: apiPaymentData.purchase?.payment_type,
  status: PaymentStatus[apiPaymentData.status],
});

export const transformPaymentsFromApi = (
  apiPayments: IApiSinglePayment[],
  purchaseTotalAmount: number,
): ISinglePayment[] => {
  let currentBalance = purchaseTotalAmount;

  return [
    ...apiPayments.map((item: IApiSinglePayment) => {
      currentBalance -= item?.paid_amount || 0;
      return transformPaymentShapeFromApi(item, currentBalance);
    }),
  ];
};
