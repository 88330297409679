import {
  ONLY_CASH_CHECK_PAYMENTS,
  PAYPAL_CLIENT_ID,
  STRIPE_KEY,
} from './app-common';

import type { IPaymentMethodOption } from 'src/interfaces';

export const IS_STRIPE_METHOD_AVAILABLE =
  !!STRIPE_KEY && !ONLY_CASH_CHECK_PAYMENTS;
export const IS_BANK_TRANSFER_METHOD_AVAILABLE = IS_STRIPE_METHOD_AVAILABLE;
export const IS_PAYPAL_METHOD_AVAILABLE =
  !!PAYPAL_CLIENT_ID && !ONLY_CASH_CHECK_PAYMENTS;

export const PaymentMethodApi = {
  BANK_TRANSFER: 'bank_transfer',
  CASH: 'cash',
  CHECK: 'check',
  DEBIT_CREDIT_CARD: 'credit_card',
  INSURANCE: 'insurance',
  PAYPAL: 'paypal',
};

export const PaymentMethod = {
  [PaymentMethodApi.BANK_TRANSFER]: 'Bank Transfer',
  [PaymentMethodApi.CASH]: 'Cash',
  [PaymentMethodApi.CHECK]: 'Check',
  [PaymentMethodApi.DEBIT_CREDIT_CARD]: 'Bank Card',
  [PaymentMethodApi.INSURANCE]: 'Insurance',
  CASH_CHECK: 'Cash/Check',
  PAYPAL: 'PayPal',
};

export const cardPaymentOption = {
  id: 'payment-method-card',
  value: PaymentMethod[PaymentMethodApi.DEBIT_CREDIT_CARD],
  apiValue: PaymentMethodApi.DEBIT_CREDIT_CARD,
};

export const bankTransferPaymentOption = {
  id: 'payment-method-bank',
  value: PaymentMethod[PaymentMethodApi.BANK_TRANSFER],
  apiValue: PaymentMethodApi.BANK_TRANSFER,
};

export const paypalPaymentOption = {
  id: 'payment-method-paypal',
  value: PaymentMethod.PAYPAL,
  apiValue: PaymentMethodApi.PAYPAL,
};

export const cashPaymentOption = {
  id: 'payment-method-cash',
  value: PaymentMethod.CASH_CHECK,
  apiValue: PaymentMethodApi.CASH,
};

export const PAYMENT_METHOD_OPTIONS: IPaymentMethodOption[] = [
  ...(IS_STRIPE_METHOD_AVAILABLE ? [cardPaymentOption] : []),
  ...(IS_BANK_TRANSFER_METHOD_AVAILABLE ? [bankTransferPaymentOption] : []),
  ...(IS_PAYPAL_METHOD_AVAILABLE ? [paypalPaymentOption] : []),
  cashPaymentOption,
];
