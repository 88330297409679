import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import CodeEnter from '../code-enter';
import PhoneEnter from '../phone-enter';

import { LoginStep } from 'src/constants';
import type { LoginInputsProps } from './login-inputs.props';

import './login-inputs.scss';

const LoginInputs = observer(({ className, store }: LoginInputsProps) => {
  const renderInputs = () => {
    switch (store.step) {
      case LoginStep.PHONE_ENTER:
        return (
          <PhoneEnter className="login-inputs__phone-enter" store={store} />
        );
      case LoginStep.CODE_ENTER:
        return <CodeEnter className="login-inputs__code-enter" store={store} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={classNames('login-inputs', className)}>
      {renderInputs()}
    </div>
  );
});

export default LoginInputs;
