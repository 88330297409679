import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Button, Input } from 'src/components/ui-components';

import { Mask } from 'src/constants';
import type { CodeEnterProps } from './code-enter.props';

import './code-enter.scss';

const CodeEnter = observer(({ className, store }: CodeEnterProps) => (
  <div className={classNames('code-enter', className)}>
    <div className="code-enter__data">
      <Input
        id="codeEnterInput"
        isDisabled={store.isLoading}
        isValid={!store.codeRequestError}
        textError={store.codeRequestError}
        label="SMS Code"
        mask={Mask.CODE}
        onChange={store.setCode}
        value={store.code}
        isSpacedError
      />
    </div>
    <div className="code-enter__buttons">
      <Button
        isDisabled={!store.isFormCodeValid || store.isLoading}
        label="Sign In"
        onClick={store.sendCodeCheckRequest}
        theme="filled"
        size="oversize"
      />
      <Button
        isDisabled={store.isLoading || store.isNewCodeRequestDisabled}
        label="Get New Code"
        onClick={store.getNewVerificationCode}
        theme="outlined"
        size="oversize"
      />
      {store.isNewCodeRequestDisabled && (
        <p className="code-enter__data-hint">
          New code is sent. You can resend it in{' '}
          <span className="code-enter__data-hint-time">
            {store.timerRemainingTime}.
          </span>
        </p>
      )}
    </div>
  </div>
));

export default CodeEnter;
