import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { STRIPE_KEY } from 'src/constants';

export default function useStripe(stripeAccount: string = '') {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  useEffect(() => {
    const fetchStripeObject = async () => {
      if (stripeAccount && STRIPE_KEY) {
        const result = await loadStripe(STRIPE_KEY, {
          stripeAccount,
        });
        setStripePromise(result);
      }
    };
    fetchStripeObject();
  }, [stripeAccount]);

  return {
    stripePromise,
  };
}
