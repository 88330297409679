import React from 'react';
import classNames from 'classnames';

import Radiobox from '../radiobox';

import type { IOption } from 'src/interfaces';
import type { RadioboxListProps } from './radiobox-list.props';

const RadioboxList = ({
  className,
  isDisabled,
  listItems,
  name,
  onChange,
  selectedItem,
}: RadioboxListProps) => (
  <div className={classNames('radiobox-list', className)}>
    {listItems.map((item: IOption) => (
      <Radiobox
        isChecked={selectedItem ? selectedItem.id === item.id : false}
        id={`radioOption-${item.id}`}
        isDisabled={isDisabled}
        key={item.value}
        label={item.value}
        name={name}
        onChange={onChange}
      />
    ))}
  </div>
);

export default RadioboxList;
