import React from 'react';
import classNames from 'classnames';

import type { LabeledValueProps } from './labeled-value.props';

import './labeled-value.scss';

function LabeledValue({
  className,
  label,
  value,
  valueWeight,
}: LabeledValueProps) {
  return (
    <span className={classNames('labeled-value', className)}>
      <span className="labeled-value__label">{label}:</span>
      <span
        className={classNames(
          'labeled-value__value',
          valueWeight && `labeled-value__value_${valueWeight}`,
        )}
      >
        {value}
      </span>
    </span>
  );
}

export default LabeledValue;
