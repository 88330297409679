import React from 'react';
import { observer } from 'mobx-react-lite';

import { Loader } from 'src/components/common-components';
import Orders from '../orders';

import ordersStore from 'src/stores/orders-store';

const MainPageContent = observer(() => {
  const { isContentLoading } = ordersStore;

  return (
    <div className="main-page__content">
      {isContentLoading ? (
        <Loader className="loader_position_overlay" />
      ) : (
        <Orders />
      )}
    </div>
  );
});

export default MainPageContent;
