import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/components/ui-components';

import type { CashFormProps } from './cash-form.props';

import './cash-form.scss';

const CashForm = observer(
  ({ cashCheckStore, isVisible, loadingPayment }: CashFormProps) => {
    const { isCheckOptionAgreed, toggleCheckOption } = cashCheckStore;

    return (
      <div
        className={classNames('cash-form', !isVisible && 'cash-form_hidden')}
      >
        <div className="cash-form__wrapper">
          <p className="cash-form__text">Pay directly to the cemetery</p>
        </div>
        <Checkbox
          className="cash-form__checkbox"
          isChecked={isCheckOptionAgreed}
          isDisabled={loadingPayment}
          id="paymentPageCashCheckbox"
          label="I will pay by cash or bank check at the cemetery"
          onChange={toggleCheckOption}
        />
      </div>
    );
  },
);

export default CashForm;
