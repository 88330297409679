import type { RefObject } from 'react';
import type { AxiosResponse } from 'axios';
import { makeAutoObservable } from 'mobx';

import type PaymentFormStore from 'src/stores/payment-form/payment-form-store';
import processingStore from 'src/stores/processing-store';

import api from 'src/api';
import { ApiRoute, ApiStatus, Error } from 'src/constants';
import { formatUSDMoneyToCents } from 'src/utils';
import type {
  IPaypalAcceptPaymentResponse,
  IPaypalCreateOrderResponse,
} from 'src/interfaces';

class PaypalStore {
  amountInputRef: RefObject<HTMLInputElement> | null = null;
  paymentId: string;
  paymentTotal: number;
  setFormError: PaymentFormStore['setFormError'];

  constructor(
    setFormError: PaymentFormStore['setFormError'],
    paymentId: string,
    paymentTotal: number,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.setFormError = setFormError;
    this.paymentId = paymentId;
    this.paymentTotal = paymentTotal;
  }

  setAmountInputRef(ref: RefObject<HTMLInputElement> | null) {
    this.amountInputRef = ref;
  }

  handleError(err: Record<string, unknown>) {
    console.error(err);
    this.setFormError(Error.DEFAULT);
  }

  async handleCreateOrder() {
    this.setFormError('');

    try {
      const amount =
        formatUSDMoneyToCents(this.amountInputRef?.current?.value ?? '') ||
        this.paymentTotal;
      const result = await api.post<
        IPaypalCreateOrderResponse,
        AxiosResponse<IPaypalCreateOrderResponse>,
        { amount: string }
      >(ApiRoute.PAYPAL_CREATE_ORDER, {
        amount: amount.toString(),
      });

      if (result.data) {
        return result.data.id;
      }
    } catch (err) {
      console.error(err);
      this.setFormError(Error.DEFAULT);
    }

    return '';
  }

  async handleApprove({
    orderID: orderId,
  }: {
    facilitatorAccessToken: string;
    orderID: string;
  }) {
    if (!this.paymentId) {
      this.setFormError(Error.DEFAULT);
    }

    try {
      const result = await api.post<
        IPaypalAcceptPaymentResponse,
        AxiosResponse<IPaypalAcceptPaymentResponse>,
        { orderId: string; payment: string }
      >(ApiRoute.PAYPAL_ACCEPT_PAYMENT, {
        orderId,
        payment: this.paymentId,
      });

      if (result.status === ApiStatus.SUCCESS) {
        processingStore.setPaymentPendingProcessOn(this.paymentId || '');
      }
    } catch (err) {
      console.error(err);
      this.setFormError(Error.DEFAULT);
    }
  }
}

export default PaypalStore;
