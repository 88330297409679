import { getLotLabel } from './get-lot-label';
import { formatAsUSDOptionalCents } from './money';

import {
  LotType,
  LotTypeApi,
  PaymentType,
  PaymentTypeApi,
} from 'src/constants';
import type {
  ICemetery,
  IDataListPair,
  ILot,
  IPaymentOrderData,
  IPerson,
  IService,
  IServiceItemFormatted,
} from 'src/interfaces';

export const getCemeteryData = (cemeteryData: ICemetery): IDataListPair[] => [
  { key: 'Name:', value: cemeteryData.name },
  { key: 'Address:', value: cemeteryData.address },
  { key: 'Phone:', value: cemeteryData.phone },
  { key: 'Email:', value: cemeteryData.email },
];

export const getLotData = (lotData: ILot) => {
  const lotLabel = getLotLabel(lotData);

  const data: IDataListPair[] = [
    {
      key: `${lotLabel}:`,
      value: lotData.number,
    },
  ];

  if (
    lotData.type === LotType[LotTypeApi.PLOT] ||
    lotData.type === LotType[LotTypeApi.CREMATION_PLOT]
  ) {
    data.push({
      key: 'Location:',
      value: `Section ${lotData.section}${
        lotData.block ? `, Block ${lotData.block}` : ''
      }`,
    });
  }

  if (
    lotData.type === LotType[LotTypeApi.CRYPT] ||
    lotData.type === LotType[LotTypeApi.NICHE]
  ) {
    const { bank = '', mausoleum = '' } = lotData;
    data.push({
      key: 'Location:',
      value: `${mausoleum}, ${bank}${
        lotData.tier ? `, Tier ${lotData.tier}` : ''
      }${lotData.row ? `, Row ${lotData.row}` : ''}`,
    });
  }

  data.push({ key: 'Rights:', value: lotData.right });

  if (lotData.price) {
    data.push({ key: 'Price:', value: lotData.price });
  }

  return data;
};

export const getPersonalData = (deedOwner: IPerson) => [
  { key: 'Name:', value: deedOwner.name },
  { key: 'Address:', value: deedOwner.address },
  { key: 'Date of Birth:', value: deedOwner.dateOfBirth },
  { key: 'Phone Number:', value: deedOwner.phone },
  { key: 'Email:', value: deedOwner.email },
];

export const getPaymentData = (paymentData: IPaymentOrderData) => {
  const data: IDataListPair[] = [
    { key: 'Total:', value: paymentData.total, isValueBold: true },
  ];

  if (paymentData.type) {
    data.push({
      key: 'Payment Type:',
      value: paymentData.type,
    });
  }

  if (paymentData.type === PaymentType[PaymentTypeApi.INSTALLMENT]) {
    data.push({
      key: 'Down Payment:',
      value: paymentData.downPayment,
    });

    if (paymentData.paymentFrequency) {
      data.push({
        key: 'Payment Frequency:',
        value: paymentData.paymentFrequency,
      });
    }

    data.push({
      key: 'Regular Payments:',
      value: paymentData.monthlyPayment,
    });
  }

  return data;
};

export const getFormattedServices = (services: IService[] = []) =>
  services.reduce((prevVal: IServiceItemFormatted[], currentVal) => {
    const serviceCountArray = new Array(currentVal.count)
      .fill('')
      .map((serviceItem, idx) => ({
        name: currentVal.service.name,
        price: formatAsUSDOptionalCents(currentVal.service.price),
        isPrepaid: currentVal.prepaid_count
          ? idx < currentVal.prepaid_count
          : false,
      }));

    return [...prevVal, ...serviceCountArray];
  }, []);

export const getServices = (services: IServiceItemFormatted[]) =>
  services.map(({ price, name }) => [
    {
      key: 'Service name:',
      value: name,
    },
    {
      key: 'Price:',
      value: price,
    },
  ]);

export const getTotal = (
  total: string,
  goodsAndServicesTax?: string,
  perpetualCare?: string,
  discount?: string,
): IDataListPair[] => [
  {
    key: 'Discount/credit applied:',
    value: discount ? `-${discount}` : '$0',
  },
  ...(goodsAndServicesTax
    ? [
        {
          key: 'GST:',
          value: goodsAndServicesTax,
        },
      ]
    : []),
  ...(perpetualCare
    ? [
        {
          key: 'Perpetual Care:',
          value: perpetualCare,
        },
      ]
    : []),
  { key: 'Total:', value: total },
];
