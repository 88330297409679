import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/icon-arrow.svg';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderStatus } from 'src/constants';
import { getLot, getOrderAdditionalLabel } from 'src/utils';
import type { OrderItemProps } from './order-item.props';

import './order-item.scss';

const OrderItem = observer(
  ({ agreementNumber, item, status }: OrderItemProps) => {
    const { id } = item;
    const url =
      item.status === OrderStatus.PENDING_SIGNATURE
        ? `${AppRoute.SIGNING}/${id}`
        : `${AppRoute.DASHBOARD}/${id}`;
    const handleOrderClick = () => {
      ordersStore.setSelectedOrderId(id);
    };
    const additionalInfo = getOrderAdditionalLabel(item);

    return (
      <li className="order-item">
        <Link
          className="order-item__content"
          onClick={handleOrderClick}
          to={url}
        >
          <p className="order-item__title">{agreementNumber}</p>
          <div className="order-item__number-wrapper">
            {item.inventoryLot ? (
              <p className="order-item__number">{getLot(item.inventoryLot)}</p>
            ) : !!item.lots?.length ? (
              item.lots?.map((lot) => (
                <p key={lot.number} className="order-item__number">
                  {getLot(lot)}
                </p>
              ))
            ) : (
              <></>
            )}
            {additionalInfo && (
              <p className="order-item__number">
                {getOrderAdditionalLabel(item)}
              </p>
            )}
          </div>
          <div className="order-item__status">{status}</div>
          <ArrowIcon className="order-item__arrow" />
        </Link>
      </li>
    );
  },
);

export default OrderItem;
