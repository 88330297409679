import { makeAutoObservable, reaction } from 'mobx';

import {
  AppRoute,
  OrderStatus,
  PaymentStatusApi,
  ProcessingMessage,
  ProcessingStatus,
  WebSocketMessageType,
} from 'src/constants';
import { getOrderData, getOrderPaymentData } from 'src/utils';

const TIMEOUT_MS = 15000;

class ProcessingStore {
  id: string = '';

  status: ProcessingStatus | '' = '';

  redirectPath: string = '';

  socketConnection: WebSocket | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.status,
      (status) => {
        if (status === ProcessingStatus.OFF) {
          this.disconnectSocket();
        } else if (status === ProcessingStatus.AGREEMENT_SIGNING) {
          this.connectSocket(this.id);
          this.checkAgreementSignedStatus();
        } else if (status === ProcessingStatus.PAYMENT_WAITING) {
          this.checkOrderPaidPaymentStatus();
        }
      },
    );
  }

  get message() {
    return this.status === ProcessingStatus.AGREEMENT_SIGNING
      ? ProcessingMessage.AGREEMENT
      : '';
  }

  get isProcessingPageShown() {
    return this.status === ProcessingStatus.AGREEMENT_SIGNING;
  }

  checkOrderPaidPaymentStatus() {
    setTimeout(async () => {
      if (!this.id) {
        return;
      }

      const paymentData = await getOrderPaymentData(this.id);

      if (
        paymentData?.status === PaymentStatusApi.PAID &&
        this.status === ProcessingStatus.PAYMENT_WAITING
      ) {
        this.setStatus(ProcessingStatus.PAYMENT_SUCCESS);
      } else if (this.status === ProcessingStatus.PAYMENT_WAITING) {
        this.checkOrderPaidPaymentStatus();
      }
    }, TIMEOUT_MS);
  }

  checkAgreementSignedStatus() {
    setTimeout(async () => {
      const orderData = await getOrderData(this.id);

      if (
        orderData?.status === OrderStatus.PENDING_SIGNATURE &&
        this.status === ProcessingStatus.AGREEMENT_SIGNING
      ) {
        this.checkAgreementSignedStatus();
      } else if (this.status !== ProcessingStatus.OFF) {
        this.clearStatus();
        this.disconnectSocket();
      }
    }, TIMEOUT_MS);
  }

  setId(id: string) {
    this.id = id;
  }

  setRedirectPath(path: string) {
    this.redirectPath = path;
  }

  setStatus(status: ProcessingStatus | '') {
    this.status = status;
  }

  setAgreementProcessOn(id: string) {
    this.id = id;
    this.redirectPath = `${AppRoute.RESULTS}/${id}`;
    this.setStatus(ProcessingStatus.AGREEMENT_SIGNING);
  }

  setSuccessPaymentProcessOn(paymentId: string) {
    // requires cemetery confirmation of payment if cash or 3 days lasts bank transfer
    this.id = paymentId;
    this.setStatus(ProcessingStatus.PAYMENT_SUCCESS);
  }

  setPaymentPendingProcessOn(paymentId: string) {
    // requires instant api check of status change
    this.id = paymentId;
    this.setStatus(ProcessingStatus.PAYMENT_WAITING);
  }

  setProcessRedirect() {
    setTimeout(() => {
      this.clearStatus();
      this.disconnectSocket();
    }, TIMEOUT_MS);
  }

  connectSocket(id: string) {
    this.disconnectSocket();

    this.socketConnection = new WebSocket(`${window.WS_URL}?topic=${id}`);
    this.socketConnection.onopen = () => console.info('opened ws connection');
    this.socketConnection.onclose = () => console.info('closed ws connection');
    this.socketConnection.onmessage = (evt: any) => {
      const message = JSON.parse(evt.data);

      if (message.type === WebSocketMessageType.PAID) {
        this.setStatus(ProcessingStatus.PAYMENT_SUCCESS);
      }

      if (message.type === WebSocketMessageType.SIGNED) {
        this.clearStatus();
      }
    };
  }

  disconnectSocket() {
    if (this.socketConnection) {
      this.socketConnection.close();
    }
  }

  clearStatus() {
    this.id = '';
    this.setStatus(ProcessingStatus.OFF);
  }

  clearStore() {
    this.disconnectSocket();
    this.clearStatus();
    this.redirectPath = '';
    this.socketConnection = null;
  }
}

export default new ProcessingStore();
