import React from 'react';
import classNames from 'classnames';

import type { DataListPairsProps } from './data-list-pairs.props';

const DataListPairs = ({ id, pairs }: DataListPairsProps) => (
  <div className="data-list__pairs">
    {pairs.map(({ key, value = '', isValueBold }, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`datalistPair${id}${idx}`} className="data-list__pair">
        <span className="data-list__pair-key">{key}</span>
        <span
          className={classNames(
            'data-list__pair-value',
            isValueBold && 'data-list__pair-value_bold',
          )}
        >
          {value}
        </span>
      </div>
    ))}
  </div>
);

export default DataListPairs;
