import type { ILot } from 'src/interfaces';

export const enum LotTypeApi {
  PLOT = 'plot',
  CREMATION_PLOT = 'cremation_plot',
  NICHE = 'niche',
  CRYPT = 'crypt',
}

export const LotType = {
  [LotTypeApi.PLOT]: 'Plot',
  [LotTypeApi.CREMATION_PLOT]: 'Cremation Plot',
  [LotTypeApi.NICHE]: 'Niche',
  [LotTypeApi.CRYPT]: 'Crypt',
};

export const GRAVE_PROPERTIES: { label: string; field: keyof ILot }[] = [
  { label: 'Section', field: 'section' },
  { label: 'Block', field: 'block' },
];

export const MAUSOLEUM_PROPERTIES: { label: string; field: keyof ILot }[] = [
  { label: 'Bank', field: 'bank' },
  { label: 'Tier', field: 'tier' },
  { label: 'Row', field: 'row' },
  { label: 'Type', field: 'type' },
];
