import { makeAutoObservable } from 'mobx';

import processingStore from 'src/stores/processing-store';

import api from 'src/api';
import { PaymentMethodApi, PaymentStatusApi } from 'src/constants';

class CashCheckStore {
  isCheckOptionAgreed: boolean = true;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsCheckOptionAgreed(isCheckOptionAgreed: boolean) {
    this.isCheckOptionAgreed = isCheckOptionAgreed;
  }

  toggleCheckOption() {
    this.isCheckOptionAgreed = !this.isCheckOptionAgreed;
  }

  get isFormValid() {
    return this.isCheckOptionAgreed;
  }

  async sendCashPendingRequest(paymentId: string, onError: () => void) {
    const request = {
      id: paymentId,
      method: PaymentMethodApi.CASH,
      status: PaymentStatusApi.PENDING,
    };

    api
      .patch('/payments', request)
      .then(() => {
        processingStore.setSuccessPaymentProcessOn(paymentId);
      })
      .catch((err) => {
        console.error(err);
        onError();
      });
  }
}

export default CashCheckStore;
