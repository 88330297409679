import React from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import { Label, TextError } from 'src/components/ui-components';

import { LabelTheme } from 'src/constants';
import type { InputProps } from './input.props';

import './input.scss';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      icon,
      id,
      isDisabled = false,
      isValid = true,
      label,
      labelColor = LabelTheme.DARK,
      mask = '',
      maskPlaceholder,
      onBlur,
      onChange,
      onFocus,
      onKeyPress,
      placeholder,
      textError = '',
      type = 'text',
      value,
      isSpacedError,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur?.();
    };

    const handleChange = (
      event:
        | React.SyntheticEvent<HTMLInputElement>
        | React.SyntheticEvent<HTMLTextAreaElement>,
    ) => onChange(event.currentTarget.value);

    const renderInputMask = () => (
      <>
        <InputMask
          className="input__field"
          id={id}
          disabled={isDisabled}
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          type={type}
          value={value}
        />
        {icon && <span className="input__icon">{icon}</span>}
      </>
    );

    const renderInput = () => (
      <>
        <input
          className="input__field"
          disabled={isDisabled}
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          ref={ref}
          type={type}
          value={value}
        />
        {icon && <span className="input__icon">{icon}</span>}
      </>
    );

    const inputClasses = classNames('input', className, {
      input_disabled: isDisabled,
      input_focus: isFocused,
      input_icon: !!icon,
      input_invalid: !isValid && !isFocused,
      input_label: !!label,
      input_mask: !!mask,
      input_placeholder: !!placeholder,
    });

    const labelClasses = classNames('input__label', {
      label_disabled: isDisabled,
      'label_valued-disabled': isDisabled && value,
      label_invalid: !isValid && !isFocused,
      label_placeholder: !value && !isFocused,
      'label_placeholder-light':
        !value && !isFocused && labelColor === LabelTheme.LIGHT,
    });

    return (
      <div className={inputClasses}>
        {label && <Label id={id} label={label} className={labelClasses} />}
        {!label && placeholder && !isFocused && !value && (
          <span className="input__placeholder">{placeholder}</span>
        )}
        {mask ? renderInputMask() : renderInput()}
        {!isValid && !isFocused && !isDisabled && textError && (
          <TextError
            className={classNames(
              'input__error-text',
              isSpacedError && 'input__error-text_spaced',
            )}
            textError={textError}
          />
        )}
      </div>
    );
  },
);

export default Input;
