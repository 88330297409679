import { useEffect } from 'react';

export default function useOnClickOutside(ref: any, handler: () => void) {
  useEffect(() => {
    const listener = (evt: any) => {
      if (!ref.current || ref.current.contains(evt.target)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}
