import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import globalStore from 'src/stores/global-store';

import { AppRoute } from 'src/constants';

const LogoutPage = observer(() => {
  globalStore.logOut();

  return <Redirect to={AppRoute.LOGIN} />;
});

export default LogoutPage;
