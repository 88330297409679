import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DetailsPage from 'src/pages/details-page/details-page';
import LoginPage from 'src/pages/login-page';
import LogoutPage from 'src/pages/logout-page/logout-page';
import MainPage from 'src/pages/main-page';
import PaymentPage from 'src/pages/payment-page';
import PolicyPage from 'src/pages/policy-page';
import ProcessingPage from 'src/pages/processing-page';
import ResultsPage from 'src/pages/results-page';
import SigningPage from 'src/pages/signing-page/signing-page';
import TermsPage from 'src/pages/terms-page';
import { Header, Loader } from 'src/components/common-components';
import ScrollToTop from 'src/components/common-components/scroll-to-top';

import globalStore from 'src/stores/global-store';
import processingStore from 'src/stores/processing-store';

import { AppRoute } from 'src/constants';
import useConditionalRedirect from 'src/hooks/use-conditional-redirect';
import useVhValue from 'src/hooks/use-vh-value';

import './app.scss';

const App = observer(() => {
  useVhValue();
  useConditionalRedirect();

  useEffect(() => {
    const purchaseId = window.location.pathname.replace('/', '');
    const searchParams = new URLSearchParams(window.location.search);
    const isSigningInProcess = searchParams.get('event') === 'signing_complete';
    const token = searchParams.get('token') || '';

    if (isSigningInProcess) {
      processingStore.setAgreementProcessOn(purchaseId);
    }

    if (token) {
      globalStore.loginByToken(token, purchaseId);
    } else {
      globalStore.init();
    }
  }, []);

  if (globalStore.isLoading) {
    return <Loader className="loader_position_overlay" />;
  }

  if (processingStore.isProcessingPageShown) {
    return <ProcessingPage />;
  }

  if (!globalStore.token) {
    return (
      <Switch>
        <Route exact path={AppRoute.LOGIN} component={LoginPage} />
        <Route exact path={AppRoute.TERMS} component={TermsPage} />
        <Route exact path={AppRoute.POLICY} component={PolicyPage} />
        <Redirect to={AppRoute.LOGIN} />
      </Switch>
    );
  }

  return (
    <div className="app">
      <ScrollToTop>
        <Header />
        <Switch>
          <Route exact path={AppRoute.LOGOUT} component={LogoutPage} />
          <Route exact path={AppRoute.DASHBOARD} component={MainPage} />
          <Route
            exact
            path={`${AppRoute.PAYMENT}/:id`}
            component={PaymentPage}
          />
          <Route
            exact
            path={`${AppRoute.DASHBOARD}/:id`}
            component={DetailsPage}
          />
          <Route
            exact
            path={`${AppRoute.SIGNING}/:id`}
            component={SigningPage}
          />
          <Route exact path={AppRoute.TERMS} component={TermsPage} />
          <Route exact path={AppRoute.POLICY} component={PolicyPage} />
          <Route
            exact
            path={`${AppRoute.RESULTS}/:id`}
            component={ResultsPage}
          />
          <Redirect to={AppRoute.DASHBOARD} />
        </Switch>
      </ScrollToTop>
    </div>
  );
});

export default App;
