import React, { useEffect } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/components/ui-components';

import type { CreditCardFormProps } from './credit-card-form.props';

import './credit-card-form.scss';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#3b3b3b',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'uppercase',
      color: '#3b3b3b',
      '::placeholder': {
        color: '#b9b9b9',
      },
    },
    invalid: {
      color: '#d95151',
    },
  },
};

const CreditCardForm = observer(
  ({
    creditCardStore,
    isCardCanBeSaved,
    isVisible,
    loadingPayment,
  }: CreditCardFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const {
      shouldSaveCard,
      handleCardElementChange,
      handleCvcElementChange,
      handleExpiryElementChange,
      setElements,
      setStripe,
      toggleShouldSaveCard,
    } = creditCardStore;

    useEffect(() => {
      setElements(elements);
      setStripe(stripe);

      return () => {
        setElements(null);
        setStripe(null);
      };
    }, [elements, stripe, setElements, setStripe]);

    return (
      <div
        className={classNames(
          'credit-card-form',
          !isVisible && 'credit-card-form_hidden',
        )}
      >
        <div className="credit-card-form__inputs">
          <CardNumberElement
            className="credit-card-form__input  credit-card-form__input_card"
            options={CARD_OPTIONS}
            onChange={handleCardElementChange}
          />
          <CardExpiryElement
            className="credit-card-form__input  credit-card-form__input_expiry"
            options={CARD_OPTIONS}
            onChange={handleExpiryElementChange}
          />
          <CardCvcElement
            className="credit-card-form__input  credit-card-form__input_cvc"
            options={CARD_OPTIONS}
            onChange={handleCvcElementChange}
          />
        </div>
        {isCardCanBeSaved && (
          <div className="credit-card-form__save-block">
            <Checkbox
              className="credit-card-form__checkbox"
              isChecked={shouldSaveCard}
              isDisabled={loadingPayment}
              id="paymentFormBillingSaveCheckbox"
              label="Save this card for recurring instalment payments"
              onChange={toggleShouldSaveCard}
            />
          </div>
        )}
      </div>
    );
  },
);

export default CreditCardForm;
