export const RightsApi = {
  COUCH: 'couch',
  DCOUCH: 'dcouch',
  DOUBLE: 'double',
  EIGHT: 'eight',
  ELEVEN: 'eleven',
  END_TO_END: 'end_to_end',
  END_TO_END_TO_END: 'end_to_end_to_end',
  FOUR: 'four',
  FIVE: 'five',
  NICHE_GLASS_FRONT: 'niche_glass_front',
  NINE: 'nine',
  OVERSIZE_SINGLE: 'oversize_single',
  OVERSIZE_END_TO_END: 'oversize_end_to_end',
  SEVEN: 'seven',
  SIDE_BY_SIDE: 'side_by_side',
  SINGLE: 'single',
  SIX: 'six',
  SMALL: 'small',
  SPECIAL_SINGLE: 'special_single',
  TEN: 'ten',
  TRIPLE: 'triple',
  TWELVE: 'twelve',
};

export const Rights = {
  [RightsApi.COUCH]: 'Couch',
  [RightsApi.DCOUCH]: 'Dcouch',
  [RightsApi.DOUBLE]: 'Double',
  [RightsApi.EIGHT]: 'Eight',
  [RightsApi.ELEVEN]: 'Eleven',
  [RightsApi.END_TO_END]: 'End to End',
  [RightsApi.END_TO_END_TO_END]: 'End to End to End',
  [RightsApi.FOUR]: 'Four',
  [RightsApi.FIVE]: 'Five',
  [RightsApi.NICHE_GLASS_FRONT]: 'Glass Front Niche',
  [RightsApi.NINE]: 'Nine',
  [RightsApi.OVERSIZE_END_TO_END]: 'Oversize End to End',
  [RightsApi.OVERSIZE_SINGLE]: 'Oversize Single',
  [RightsApi.SEVEN]: 'Seven',
  [RightsApi.SIDE_BY_SIDE]: 'Side by Side',
  [RightsApi.SINGLE]: 'Single',
  [RightsApi.SIX]: 'Six',
  [RightsApi.SMALL]: 'Small',
  [RightsApi.SPECIAL_SINGLE]: 'Special Single',
  [RightsApi.TEN]: 'Ten',
  [RightsApi.TRIPLE]: 'Triple',
  [RightsApi.TWELVE]: 'Twelve',
};
